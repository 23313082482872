import React from "react";
import { isNil, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  FieldNames,
  FieldNamesToTranslationKeys,
} from "./AgreementSheetFilters";
import { getAgreementsState } from "../selectors";
import { clearFilters } from "../AgreementsList/actions";
import { initialState } from "../ReducerHelpers";

const FieldsToIgnore = [
  FieldNames.SheetOwnerId,
  FieldNames.SheetOwner,
  FieldNames.Search,
  FieldNames.SearchType,
];

const FormatDisplay = (item, tState) => {
  let value = item.value;

  switch (item.field) {
    case FieldNames.SheetStates:
      value = (value ?? []).map((enumString) => tState(enumString)).join(", ");
      break;
    case FieldNames.IsCrystallised:
    case FieldNames.IsUncrystallised:
    case FieldNames.IsArchived:
      value = "";
  }

  return `${item.label}${value && ":"} ${value}`;
};

const AgreementFilterDisplay = () => {
  const { t } = useTranslation("agreements", { keyPrefix: "AgreementFilters" });
  const { t: tState } = useTranslation("enums", { keyPrefix: "SheetState" });
  const { filters } = useSelector(getAgreementsState);
  const dispatch = useDispatch();

  const onClear = () => dispatch(clearFilters());

  const items = filters
    ? Object.keys(filters)
        .filter((f) => !FieldsToIgnore.some((x) => x == f))
        .map((k) => {
          return {
            label: t(FieldNamesToTranslationKeys[k]),
            field: k,
            value: filters[k],
          };
        })
    : [];

  if (!items.length) return null;

  return (
    <div className="filter-display">
      {items
        .filter((i) => !isNil(i.value) && i.value != "")
        .map((item) => (
          <div className="filter-display-item" key={item.field}>
            {FormatDisplay(item, tState)}
          </div>
        ))}

      {!isEqual(filters, initialState.filters) && (
        <div className="clear-button" onClick={onClear}>
          (clear)
        </div>
      )}
    </div>
  );
};

export default AgreementFilterDisplay;
