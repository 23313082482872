import React from "react";
import { Form, Format } from "@redriver/cinnamon";
import { FixedInput, FixedBoolean } from "components/forms";
import classNames from "classnames";
import { downloadLargeFormatPrinting } from "features/Sheets/ManageSheet";
import { DownloadConfirmationModal } from "features/Sheets/ManageSheet/Shared";

const CustomerStepLFScheduleItem = ({
  printerName,
  sheetId,
  unitInfo,
  meterField,
  sheetDetailsSaving,
}) => {
  return (
    <React.Fragment>
      <div className="customer-view-item">
        <div className="col col-1 bold centered">
          <Form.Input field="displayCode" readOnly />
        </div>
        <div className="col col-2">
          <div>{unitInfo}</div>
        </div>
        <div className="col col-3 centered">
          <FixedBoolean
            field="includeManagedVol"
            toggle
            className="toggle "
            hideIndicator={true}
            disabled={
              (meterField.unit && meterField.unit.alwaysUnmanaged) ||
              sheetDetailsSaving
            }
          />
        </div>
        <div className="col col-model centered customer-view-model">
          <div>
            <DownloadConfirmationModal
              sheetId={sheetId}
              sheetDownloadFunction={downloadLargeFormatPrinting}
              isAdmin={false}
              buttonContent={printerName}
              hideButton={true}
            />
          </div>
        </div>
        <div className="col col-6 centered quart-align">
          <FixedInput
            field="minQuarterlyVolume"
            inline
            width={9}
            required
            formatNumber
            hideIndicator={true}
            decimalPlaces={0}
          />
        </div>
        <div className="col col-7 customer-view-cpu centered">
          <strong
            className={classNames(
              "cost-per-unit",
              "calculable-field",
              meterField.costPerUnit < 0 && "negative"
            )}
          >
            <div className="customer-view-at">@</div>
            {process.env.USE_MINOR_CURRENCY ? (
              <Format.Number
                value={meterField.costPerUnit || 0}
                decimalPlaces={{ min: 0, max: 3 }}
                suffix={process.env.MINOR_CURRENCY}
              />
            ) : (
              <Format.Number
                value={meterField.costPerUnit || 0}
                decimalPlaces={{ min: 0, max: 5 }}
                prefix={process.env.MAJOR_CURRENCY}
              />
            )}
          </strong>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerStepLFScheduleItem;
