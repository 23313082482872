import {
  calcScheduleItemCode,
  shouldPreserveScheduleItemCodes,
} from "../../helpers";
import { newFixedInput } from "components/forms";

export const updateIncomingSchItems = (newSheetData, unitData) => {
  const schItem = newSheetData?.incomingSchItem;
  if (!schItem) return;
  let unitId = schItem?.unit?.value;
  if (!unitId) return;
  let matchedUnit = unitData.find((x) => x.id === unitId);

  if (!matchedUnit) return;
  //update unit code and name
  schItem.minSalePrice = matchedUnit.minSalePrice;
  schItem.vMinSalePrice = matchedUnit.vMinSalePrice;
  schItem.vMinVolume = matchedUnit.vMinVolume;
  schItem.defaultMinSalePrice = matchedUnit.defaultMinSalePrice;
  schItem.defaultMinVolume = matchedUnit.defaultMinVolume;

  schItem.tier1DefaultPercentage = matchedUnit.tier1DefaultPercentage;
  schItem.tier2DefaultPercentage = matchedUnit.tier2DefaultPercentage;
  schItem.tier2DefaultValue = matchedUnit.tier2DefaultValue;
  schItem.tier3DefaultValue = matchedUnit.tier3DefaultValue;

  schItem.vMinPercentageTier1 = matchedUnit.vMinPercentageTier1;
  schItem.vMaxPercentageTier1 = matchedUnit.vMaxPercentageTier1;
  schItem.vMaxPercentageTier2 = matchedUnit.vMaxPercentageTier2;
  schItem.vMinValueTier2 = matchedUnit.vMinValueTier2;
  schItem.vMaxPercentageTier3 = matchedUnit.vMaxPercentageTier3;
  schItem.vMinValueTier3 = matchedUnit.vMinValueTier3;

  schItem.unit.unitCode = matchedUnit.code;
  schItem.unit.unitName = matchedUnit.name;
  schItem.unit.overwritable = matchedUnit.overwritable;
  schItem.unit.alwaysUnmanaged = matchedUnit.alwaysUnmanaged;
  schItem.unit.alwaysManaged = matchedUnit.alwaysManaged;
  if (schItem.costPerClick) {
    schItem.costPerClick.value = matchedUnit.defaultMinSalePrice || 0;
  } else {
    schItem.costPerClick = newFixedInput(matchedUnit.defaultMinSalePrice || 0);
  }
  if (schItem.minQuarterly) {
    schItem.minQuarterly.value = matchedUnit.defaultMinVolume || 0;
  } else {
    schItem.minQuarterly = newFixedInput(matchedUnit.defaultMinVolume || 0);
  }
  if (!schItem.displayCode || !shouldPreserveScheduleItemCodes(newSheetData)) {
    schItem.displayCode = calcScheduleItemCode(
      schItem,
      newSheetData.systemScheduleItems,
    );
  }
};
