import {
    apiGet,
    apiPost,
    apiPut,
    apiDelete
} from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_CLIENT_AUTH_SIGNATORIES";

const ActionTypes = {
    GetClientAuthorisedSignatories: `${NAMESPACE}/GET_CLIENT_AUTH_SIGNATORIES`,
    GetClientAuthorisedSignatory: `${NAMESPACE}/GET_CLIENT_AUTH_SIGNATORY`,
    AddClientAuthorisedSignatory: `${NAMESPACE}/ADD_CLIENT_AUTH_SIGNATORY`,
    UpdateClientAuthorisedSignatory: `${NAMESPACE}/UPDATE_CLIENT_AUTH_SIGNATORY`,
    DeleteClientAuthorisedSignatory: `${NAMESPACE}/DELETE_CLIENT_AUTH_SIGNATORY`
};

export const getClientAuthorisedSignatories = ({ filters, pagination }, { clientId }) =>
    apiGet(ActionTypes.GetClientAuthorisedSignatories, `clients/${clientId}/authorised-signatories`, { ...filters, ...pagination });

export const getClientAuthorisedSignatory = ({ clientId, itemId }) => apiGet(ActionTypes.GetClientAuthorisedSignatory, `clients/${clientId}/authorised-signatories/${itemId}`);

export const addClientAuthorisedSignatory = (data, { clientId }) => apiPost(ActionTypes.AddClientAuthorisedSignatory, `clients/${clientId}/authorised-signatories`, data);

export const updateClientAuthorisedSignatory = (data, { clientId, itemId }) =>
    apiPut(ActionTypes.UpdateClientAuthorisedSignatory, `clients/${clientId}/authorised-signatories/${itemId}`, data);

export const deleteClientAuthorisedSignatory = ({ clientId, itemId }) =>
    apiDelete(ActionTypes.DeleteClientAuthorisedSignatory, `clients/${clientId}/authorised-signatories/${itemId}`);
