import React from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "features/../../../shared/components/buttons";
import { Form, ModalBuilder, useFormField } from "@redriver/cinnamon";
import Add from "features/../../../shared/assets/blue-add.svg";
import { newScheduleItem, calculateForm } from "features/Sheets/ManageSheet";
import { FixedInput, FixedLookupDropdown } from "components/forms";
import { UserType } from "features/../../../shared/constants/enums";
import { LookupTypes as SettingsLookupTypes } from "features/Settings";
import { useTranslation } from "react-i18next";
import { TranslatedModal } from "features/../../../shared/components/modals";

const blankIncomingItem = () => {
  return {
    consumerExclusive: { isFixed: false, value: false },
    costPerClick: {
      id: null,
      value: null,
      minimumValue: null,
      maximumValue: null,
      setValue: null,
    },
    costPerUnit: 0,
    displayCode: "",
    id: "",
    includeManagedVol: { isFixed: false, value: true },
    minQuarterly: {
      id: null,
      value: null,
      minimumValue: null,
      maximumValue: null,
      setValue: null,
    },
    tier1UpperBoundaryPercentage: {
      id: null,
      value: null,
      minimumValue: null,
      maximumValue: null,
      setValue: null,
    },
    tier2Adds: {
      id: null,
      value: null,
      minimumValue: null,
      maximumValue: null,
      setValue: null,
    },
    tier2UpperBoundaryPercentage: {
      id: null,
      value: null,
      minimumValue: null,
      maximumValue: null,
      setValue: null,
    },
    tier3Adds: {
      id: null,
      value: null,
      minimumValue: null,
      maximumValue: null,
      setValue: null,
    },
    tieredToner: { isFixed: false, value: false },
    tonerExclusive: { isFixed: false, value: false },
    unit: { isFixed: false, value: "" },
    vMinSalePrice: 0,
    vMinVolume: 0,
    minSalePrice: 0,
    defaultMinVolume: 0,
    tier1DefaultPercentage: 0,
    tier2DefaultPercentage: 0,
    tier2DefaultValue: 0,
    tier3DefaultValue: 0,
  };
};

const AddUnit = ({
  sheetId,
  contractType,
  hideFixedIndicator,
  userType,
  disabled,
  currentUser,
}) => {
  const checkValuesAreCorrect = (userType) => {
    if (
      isNaN(parseFloat(incomingItem.costPerClick.value)) ||
      parseFloat(incomingItem.costPerClick.value) < incomingItem.minSalePrice
    ) {
      if (userType !== UserType.HQ) {
        setIncomingItem({
          ...incomingItem,
          costPerClick: {
            ...incomingItem.costPerClick,
            value: incomingItem.minSalePrice,
          },
        });
      } else if (
        parseFloat(incomingItem.costPerClick.value) <
          incomingItem.vMinSalePrice &&
        userType === UserType.HQ
      ) {
        setIncomingItem({
          ...incomingItem,
          costPerClick: {
            ...incomingItem.costPerClick,
            value: incomingItem.vMinSalePrice,
          },
        });
      }
    }

    if (
      userType === UserType.HQ &&
      (isNaN(parseInt(incomingItem.minQuarterly.value)) ||
        parseInt(incomingItem.minQuarterly.value) < incomingItem.vMinVolume)
    ) {
      setIncomingItem({
        ...incomingItem,
        minQuarterly: {
          ...incomingItem.minQuarterly,
          value: incomingItem.vMinVolume,
        },
      });
    } else if (
      userType !== UserType.HQ &&
      (isNaN(parseInt(incomingItem.minQuarterly.value)) ||
        parseInt(incomingItem.minQuarterly.value) <
          incomingItem.defaultMinVolume)
    ) {
      setIncomingItem({
        ...incomingItem,
        minQuarterly: {
          ...incomingItem.minQuarterly,
          value: incomingItem.defaultMinVolume,
        },
      });
    }
  };

  const { t } = useTranslation("systemSchedule");
  const dispatch = useDispatch();
  const incomingItemField = "incomingSchItem",
    [incomingItem, setIncomingItem] = useFormField(incomingItemField),
    [sysSchItems, setSysSchItems] = useFormField("systemScheduleItems");

  const minorCurrency = process.env.MINOR_CURRENCY;
  const majorCurrency = process.env.MAJOR_CURRENCY;

  return (
    <ModalBuilder
      onLoaded={() => setIncomingItem(newScheduleItem("id"))}
      onCancelled={() => setIncomingItem(blankIncomingItem)}
      onSubmitted={() => {
        let {
          unit,
          costPerClick,
          minQuarterly,
          defaultMinVolume,
          defaultMinSalePrice,
          vMinSalePrice,
          vMinVolume,
          tier1DefaultPercentage,
          tier2DefaultPercentage,
          tier2DefaultValue,
          tier3DefaultValue,
          vMinPercentageTier1,
          vMaxPercentageTier1,
          vMaxPercentageTier2,
          vMinValueTier2,
          vMaxPercentageTier3,
          vMinValueTier3,
        } = incomingItem;
        setSysSchItems(
          [
            sysSchItems,
            newScheduleItem(
              "id",
              unit,
              costPerClick,
              minQuarterly,
              "",
              defaultMinVolume,
              defaultMinSalePrice,
              vMinSalePrice,
              vMinVolume,
              tier1DefaultPercentage,
              tier2DefaultPercentage,
              tier2DefaultValue,
              tier3DefaultValue,
              vMinPercentageTier1,
              vMaxPercentageTier1,
              vMaxPercentageTier2,
              vMinValueTier2,
              vMaxPercentageTier3,
              vMinValueTier3,
            ),
          ].flat(),
        );
        setIncomingItem(blankIncomingItem);
        //re-calculate
        dispatch(calculateForm(false));
      }}
      renderTrigger={(trigger) => (
        <IconButton
          className="wizard-title-button add-new-unit"
          icon={Add}
          content={t("buttons.addNewUnit")}
          onClick={trigger}
          disabled={disabled}
        />
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Add
          {...modalProps}
          className="new-sch-item"
          header={t("title")}
          submitDisabled={
            !incomingItem ||
            !incomingItem.costPerClick ||
            isNaN(parseFloat(incomingItem.costPerClick.value)) ||
            !incomingItem.minQuarterly ||
            isNaN(parseFloat(incomingItem.minQuarterly.value)) ||
            (userType === UserType.HQ &&
              (parseFloat(incomingItem.minQuarterly.value) <
                incomingItem.vMinVolume ||
                parseFloat(incomingItem.costPerClick.value) <
                  incomingItem.vMinSalePrice)) ||
            (userType !== UserType.HQ &&
              (parseFloat(incomingItem.minQuarterly.value) <
                incomingItem.defaultMinVolume ||
                parseFloat(incomingItem.costPerClick.value) <
                  incomingItem.minSalePrice))
          }
        >
          <div className="sheet-step">
            <div className="ui form highlighted-inputs">
              <div className="schedule-step">
                <Form.Object field={incomingItemField}>
                  <FixedLookupDropdown
                    inline
                    fluid
                    field="unit"
                    label={t("addNewUnitModal.unit")}
                    renderTrigger={() => {
                      let currentItem = incomingItem || {};
                      return (
                        <span>
                          {currentItem.unit && currentItem.unit.value
                            ? currentItem.displayCode
                            : "-"}
                        </span>
                      );
                    }}
                    lookup={SettingsLookupTypes.Units}
                    lookupParams={{
                      contractType,
                      isLargeFormat: false,
                      sheetId: sheetId,
                    }}
                    customEditorData={{ urlRoute: "fixed-units" }}
                    className="fix-unit-dropdown"
                    hideIndicator={true}
                    displayPriority={4}
                  />
                  <FixedInput
                    inline
                    currency
                    minorCurrency={process.env.USE_MINOR_CURRENCY}
                    required
                    formatNumber
                    field="costPerClick"
                    className="cost-per-click"
                    label={
                      process.env.USE_MINOR_CURRENCY
                        ? t("addNewUnitModal.cpi_minor", { minorCurrency })
                        : t("addNewUnitModal.cpi_major", { majorCurrency })
                    }
                    decimalPlaces={process.env.USE_MINOR_CURRENCY ? 3 : 5}
                    disabled={
                      (incomingItem || {}).unit &&
                      !incomingItem.unit.overwritable
                    }
                    hideIndicator={hideFixedIndicator}
                    displayPriority={4}
                    onBlur={() => {
                      checkValuesAreCorrect(userType);
                    }}
                    minAllowValue={
                      currentUser.isInternalUser
                        ? incomingItem?.vMinSalePrice
                        : incomingItem?.minSalePrice
                    }
                    defaultValue={incomingItem?.defaultMinSalePrice}
                  />
                  <FixedInput
                    inline
                    required
                    formatNumber
                    field="minQuarterly"
                    className="min-quarterly"
                    label={t("addNewUnitModal.minQtr")}
                    decimalPlaces={0}
                    disabled={
                      (incomingItem || {}).unit &&
                      !incomingItem.unit.overwritable
                    }
                    hideIndicator={hideFixedIndicator}
                    displayPriority={4}
                    onBlur={() => {
                      checkValuesAreCorrect(userType);
                    }}
                    minAllowValue={
                      currentUser.isInternalUser
                        ? incomingItem?.vMinVolume
                        : incomingItem?.defaultMinVolume
                    }
                    defaultValue={incomingItem?.defaultMinVolume}
                  />
                </Form.Object>
              </div>
            </div>
          </div>
        </TranslatedModal.Add>
      )}
    />
  );
};

export default AddUnit;
