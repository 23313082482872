import React from "react";
import { useTranslation } from "react-i18next";
import { ModalBuilder, Form, Table } from "@redriver/cinnamon";
import {
  PermissionCheck,
  UserTypeCheck,
} from "features/../../../shared/components/auth";
import { UserType } from "features/../../../shared/constants/enums";
import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { ipsReview } from "../actions";

const IpsReview = ({ sheet, sheetActionRequired, onSubmitted }) => {
  const { t } = useTranslation("agreements", {
    keyPrefix: "SheetActions.IpsReview",
  });
  const { sheetId, availableActions } = sheet;

  if (!availableActions[sheetActionRequired]) return null;

  return (
    <UserTypeCheck restrictTo={UserType.HQ}>
      <PermissionCheck target={Targets.Sheet} action={Actions.ReviewByIps}>
        <ModalBuilder
          withForm
          renderTrigger={(showModal) => (
            <Table.MenuItem onClick={showModal}>
              {t("TriggerText")}
            </Table.MenuItem>
          )}
          submitAction={ipsReview}
          submitParams={{ sheetId }}
          onSubmitted={onSubmitted}
          renderModal={(modalProps, formProps) => (
            <TranslatedModal.Add
              {...modalProps}
              header={t("HeaderText")}
              submitLabel={t("SubmitLabel")}
            >
              <Form {...formProps}>
                <Form.RadioGroup
                  field="approved"
                  label={t("ApproveOption.Label")}
                  options={[
                    { text: t("ApproveOption.Approve"), value: true },
                    { text: t("ApproveOption.Reject"), value: false },
                  ]}
                  required
                />
              </Form>
            </TranslatedModal.Add>
          )}
        />
      </PermissionCheck>
    </UserTypeCheck>
  );
};

export default IpsReview;
