import React from "react";
import { Message, Icon } from "semantic-ui-react";
import {
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import {
  SheetState,
  UserType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import { Targets, Actions } from "constants/permissions";

const SheetStatusMessage = ({
  state,
  stateText,
  currentUser,
  hasPermission,
  sheetViewType,
}) => {
  if (state === SheetState.PreLive_Draft || !currentUser || !state) return null;

  const isAdmin = hasPermission(Targets.SheetAdmin, Actions.Edit);
  const isInternalUser = currentUser.userType === UserType.HQ;

  return (
    <Message color="orange" icon compact className="sheet-status-message">
      <Icon name={"exclamation"} size="mini" />

      <Message.Content>
        <p>
          {"This sheet's status is"}
          <strong>{stateText}</strong>
        </p>
        {!isInternalUser &&
          !isAdmin &&
          !(
            sheetViewType === SheetViewType.IpsAdmin ||
            sheetViewType === SheetViewType.SupplierAdmin
          ) && <p>You will not be able to make any further changes.</p>}
      </Message.Content>
    </Message>
  );
};

export default withPermissions(withCurrentUser(SheetStatusMessage));
