import React from "react";
import { Table, ActionMenu } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAgreementsState } from "../../selectors";
import {
  PermissionCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import {
  SheetAction,
  SheetState,
  SheetStatus,
} from "features/../../../shared/constants/enums";

import {
  getMasterAgreement,
  getMasterAgreements,
  addExpandedAgreementOrSchedule,
  getAgreementScheduleSheets,
} from "../actions";
import {
  SubmitSheet,
  SetSheetStatus,
  RenameSheet,
  SheetStateProgressView,
  SendForApproval,
  PreApproveSheet,
  IpsReview,
  MakeSheetLive,
  ResetSheetState,
  AddAdminComment,
  AmendExpiryDate,
} from "../SheetActions";
import {
  SetSheetOwner,
  CloneSheet,
  DeleteSheet,
  CrystalliseSheet,
  UncrystalliseSheet,
  ArchiveSheet,
  UnarchiveSheet,
  ToggleSheetReadOnly,
  DuplicateToNewMaster,
} from "features/Sheets/ManageSheet/SheetActions";
import { SetCurrentSchedule } from "../SetCurrentSchedule";
import { SetReplacingSchedule } from "../SetReplacingSchedule";
import { VoidEnvelope } from "../SheetActions/VoidEnvelope";
import { SheetState as NumberedSheetState } from "features/../constants/sheetState";

const SheetActions = ({
  agreement,
  schedule,
  sheet,
  currentUser,
  hasPermission,
}) => {
  const dispatch = useDispatch();

  const { filters, companyFilters, pagination } =
    useSelector(getAgreementsState);

  const dealerId = companyFilters?.dealer?.id;
  let { sheetId, sheetName, ownedBy, isReadOnly } = sheet,
    { agreementReference, boilerPlateTandCsId } = agreement,
    { scheduleReference } = schedule;
  const { isSupplierAdminUser, isInternalUser } = currentUser;
  const isAdminUser = isInternalUser || isSupplierAdminUser;
  const canPreApproveAsSupplier =
    hasPermission(Targets.Sheet, Actions.PreApproveSupplier) &&
    sheet.state == SheetState.PreLive_AwaitingSupplierPreApproval;
  const canPreApproveAsIPS =
    isInternalUser &&
    hasPermission(Targets.Sheet, Actions.PreApproveIPS) &&
    sheet.state == SheetState.PreLive_AwaitingIpsPreApproval;
  const sheetInPreApprovalState =
    sheet.state == SheetState.PreLive_AwaitingIpsPreApproval ||
    sheet.state == SheetState.PreLive_AwaitingSupplierPreApproval;

  const sheetCanHaveEnvelopeVoided =
    sheet.stateDescriptionNumber >=
      NumberedSheetState.PreLive_WithCustomer_Docusign &&
    sheet.stateDescriptionNumber <=
      NumberedSheetState.PreLive_WithSupplierSignatory_Docusign_Checking;

  const reloadAgreement = () =>
    dispatch(getMasterAgreement(agreement.agreementId, filters));

  const reloadAgreements = () =>
    dispatch(getMasterAgreements({ filters, pagination }, companyFilters));

  const onDuplicateToNewMasterSubmitted = (response) => {
    reloadAgreements();
    dispatch(getAgreementScheduleSheets(response.scheduleId, filters));

    // Expand newly created agreement and schedule, so using the response IDs.
    dispatch(addExpandedAgreementOrSchedule(response.masterAgreementId));
    dispatch(addExpandedAgreementOrSchedule(response.scheduleId));
  };

  const reloadSheetsForSchedule = () =>
    dispatch(getAgreementScheduleSheets(schedule.scheduleId, filters));

  return (
    <Table.Menu upward>
      <CloneSheet
        sheetId={sheetId}
        name={sheetName}
        isLive={sheet.state === SheetState.Live}
      />
      {(!isReadOnly || isAdminUser) && (
        <RenameSheet sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}
      {(!isReadOnly || isAdminUser) && (
        <PermissionCheck action={Actions.Resurrect} target={Targets.Sheet}>
          <DeleteSheet
            sheetId={sheetId}
            sheetName={sheetName}
            onSubmitted={() => {
              reloadSheetsForSchedule();
              reloadAgreement();
            }}
            submitParams={{ sheetId }}
          />
        </PermissionCheck>
      )}
      <PermissionCheck
        action={Actions.DuplicateToNewMaster}
        target={Targets.Sheet}
      >
        <DuplicateToNewMaster
          sheetId={sheetId}
          name={sheetName}
          onSubmitted={onDuplicateToNewMasterSubmitted}
        />
      </PermissionCheck>
      {(!isReadOnly || isAdminUser) && <ActionMenu.Divider />}
      {(!isReadOnly || isAdminUser) && (
        <PermissionCheck target={Targets.Sheet} action={Actions.SetOwner}>
          <SetSheetOwner
            sheetId={sheetId}
            dealerId={dealerId}
            ownedBy={ownedBy}
            onSubmitted={reloadSheetsForSchedule}
          />
        </PermissionCheck>
      )}
      {isAdminUser && (
        <AddAdminComment sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}

      {isInternalUser && (
        <SetReplacingSchedule
          sheet={sheet}
          onSubmitted={reloadSheetsForSchedule}
        />
      )}

      {isAdminUser && (
        <AmendExpiryDate sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}

      {isAdminUser && <ActionMenu.Divider />}
      {process.env.TRADE_AS_IPS && <SheetStateProgressView sheet={sheet} />}

      {process.env.TRADE_AS_IPS && isAdminUser && (
        <PermissionCheck action={Actions.Reset} target={Targets.Sheet}>
          <ResetSheetState
            sheet={sheet}
            onSubmitted={reloadSheetsForSchedule}
            sheetActionRequired={SheetAction.CanResetSheetState}
          />
        </PermissionCheck>
      )}
      {sheetCanHaveEnvelopeVoided && (
        <PermissionCheck action={Actions.Void} target={Targets.Sheet}>
          <VoidEnvelope
            sheet={sheet}
            onSubmitted={() => {
              reloadSheetsForSchedule();
              reloadAgreement();
            }}
          />
        </PermissionCheck>
      )}
      {process.env.TRADE_AS_IPS &&
        (canPreApproveAsSupplier || canPreApproveAsIPS) && (
          <PreApproveSheet
            sheet={sheet}
            onSubmitted={reloadSheetsForSchedule}
          />
        )}
      {process.env.TRADE_AS_IPS && !sheetInPreApprovalState && (
        <PermissionCheck
          target={Targets.Sheet}
          action={Actions.SubmitForCustomerApproval}
        >
          <SendForApproval
            sheet={sheet}
            onSubmitted={() => {
              reloadSheetsForSchedule();
              reloadAgreement();
            }}
            scheduleReference={scheduleReference}
            agreementReference={agreementReference}
            boilerPlateTandCsId={boilerPlateTandCsId}
            sheetActionRequired={SheetAction.CanStartDocusignProcess}
          />
        </PermissionCheck>
      )}

      <IpsReview
        sheet={sheet}
        sheetActionRequired={SheetAction.CanBeReviewedByIps}
        onSubmitted={reloadSheetsForSchedule}
      />

      <MakeSheetLive
        sheet={sheet}
        sheetActionRequired={SheetAction.CanMakeLive}
        onSubmitted={() => {
          reloadSheetsForSchedule();
          reloadAgreement();
        }}
        scheduleReference={scheduleReference}
        agreementReference={agreementReference}
      />

      {!process.env.TRADE_AS_IPS && (
        <React.Fragment>
          {sheet.status === SheetStatus.Draft &&
            (!isReadOnly || isAdminUser) && (
              <PermissionCheck target={Targets.Sheet} action={Actions.Submit}>
                <SubmitSheet
                  sheet={sheet}
                  onSubmitted={reloadSheetsForSchedule}
                />
              </PermissionCheck>
            )}
          {(!isReadOnly || isAdminUser) && (
            <PermissionCheck target={Targets.Sheet} action={Actions.SetStatus}>
              <SetSheetStatus
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            </PermissionCheck>
          )}
          {isAdminUser && (
            <PermissionCheck
              target={Targets.Sheet}
              action={Actions.UpdateSheetScheduleReference}
            >
              <SetCurrentSchedule
                sheet={sheet}
                onSubmitted={reloadSheetsForSchedule}
              />
            </PermissionCheck>
          )}
        </React.Fragment>
      )}
      {(!isReadOnly || isAdminUser) && (
        <PermissionCheck
          target={Targets.Sheet}
          action={Actions.CrystalliseAndUncrystalliseSheets}
        >
          <React.Fragment>
            <UncrystalliseSheet
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
              sheetActionRequired={SheetAction.CanUncrystallise}
            />
            <CrystalliseSheet
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
              sheetActionRequired={SheetAction.CanCrystallise}
            />
          </React.Fragment>
        </PermissionCheck>
      )}
      {(!isReadOnly || isAdminUser) && sheet.isArchived && (
        <UnarchiveSheet sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}
      {(!isReadOnly || isAdminUser) && !sheet.isArchived && (
        <ArchiveSheet sheet={sheet} onSubmitted={reloadSheetsForSchedule} />
      )}
      {(!isReadOnly || isAdminUser) && (
        <PermissionCheck target={Targets.Sheet} action={Actions.SetReadOnly}>
          <React.Fragment>
            <ActionMenu.Divider />
            <ToggleSheetReadOnly
              sheet={sheet}
              onSubmitted={reloadSheetsForSchedule}
            />
          </React.Fragment>
        </PermissionCheck>
      )}
    </Table.Menu>
  );
};

export default withPermissions(withCurrentUser(withRouter(SheetActions)));
