import React from "react";
import { Form, registerLookup, apiGet } from "@redriver/cinnamon";

const ClientAuthSignsLookups = {
  GetAuthSigns: "getClientAuthSignsLookup",
};

const ActionTypes = {
  GetAuthSigns: "LOOKUPS/GET_CLIENT_AUTH_SIGNS",
};

registerLookup(
  ClientAuthSignsLookups.GetAuthSigns,
  ({ sheetId, search }) =>
    apiGet(ActionTypes.GetAuthSigns, "lookups/clients/authorised-signatories", {
      sheetId,
      queryTerm: search,
    }),
  {
    cache: false,
    transform: data =>
      Array.isArray(data)
        ? data.map(x => ({
            ...x,
            value: x.id,
            text: `${x.name} - ${x.position} - ${x.email}`,
          }))
        : [],
  }
);

export const ClientAuthSignTypeAhead = ({ sheetId, readOnly }) => (
  <Form.TypeAhead
    required
    searchSuggestions
    alwaysShowSuggestionsOnBlur
    readOnly={readOnly}
    field="authorisedSignatoryId"
    label="Authorised Signatory"
    placeholder="Search Authorised Signatories..."
    lookup={ClientAuthSignsLookups.GetAuthSigns}
    lookupParams={{
      sheetId: sheetId,
    }}
    className="client-auth-sign-dropdown"
    optionField="authorisedSignatory"
  />
);
