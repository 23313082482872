import React, { useState } from "react";
import classNames from "classnames";
import { Form, Lookup, useFormField, PageLoader } from "@redriver/cinnamon";
import { produce } from "immer";
import {
  ProductScheduleTypeDropdown,
  ProductScheduleStatusDropdown,
  productMeteredServiceLookup,
} from "./lookup";
import ApplicableItemArray from "./ApplicableItemArray";
import {
  PermissionCheck,
  UserTypeCheck,
  usePermission,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import {
  UserType,
  SheetViewType,
  ProductScheduleType,
  UserArea,
  SheetAction,
} from "features/../../../shared/constants/enums";
import ProductScheduleRemoveModal from "./ProductScheduleRemoveModal";
import SelectLocationModal from "./SelectLocationModal";
import { Icon } from "semantic-ui-react";
import { Actions, Targets } from "constants/permissions";
import { useTranslation } from "react-i18next";
import { compose } from "redux";

const ProductScheduleItems = ({
  field,
  toRemove,
  sheetId,
  itemsCanBeRemoved,
  itemsCanBeAdded,
  viewDealerAdminFields,
  salesFieldsCanBeUpdated,
  ipsFieldsCanBeUpdated,
  updateSerialNumbersOnly,
  onDropdownOpen,
  onDropdownClose,
  reinstate = false,
  readOnly = false,
  disabled = false,
  sheetViewType,
  currentUser,
  availableActions,
  hasPermission,
}) => {
  const { t } = useTranslation("productSchedule");

  const [productScheduleItems, setProductScheduleItems] = useFormField(field);
  const [renderTrigger, setRenderTrigger] = useState(true);

  const [areAllUnlocked, setAreAllUnlocked] = useState(true);

  const hasLockPermission = usePermission(
    Targets.ProductSchedule,
    Actions.Lock,
  );

  const canLockViaUserArea = (fields, currentUser) => {
    return (
      fields.lockerUserArea == null ||
      currentUser.userArea === fields.lockerUserArea ||
      currentUser.userArea === UserArea.IpsAdmin ||
      currentUser.userArea === UserArea.IpsSuperUser
    );
  };

  const checkAllAreLock = (formFields) => {
    let allUnlocked = true;
    formFields?.productScheduleItems?.forEach((ps) => {
      if (canLockViaUserArea(ps, currentUser)) {
        if (ps.isLocked === true) {
          allUnlocked = false;
        }
      }
    });
    setAreAllUnlocked(allUnlocked);
  };

  const setFieldLocking = (fields, lock) => {
    fields.isLocked = lock;
    fields.lockingEdited = true;
  };

  const setLockAllSchedules = (formFields) => {
    formFields.productScheduleItems.forEach((ps) => {
      if (canLockViaUserArea(ps, currentUser)) {
        setFieldLocking(ps, areAllUnlocked);
      }
    });
    setRenderTrigger(!renderTrigger);
  };

  const userIsInternal = currentUser.userType === UserType.HQ;
  const userIsSales =
    !userIsInternal && currentUser.userArea === UserArea.AccountManager;
  const userIsAdmin =
    !userIsInternal && currentUser.userArea === UserArea.SupplierAdmin;

  const awaitingDealerAdminApprovalCheck =
    availableActions[SheetAction.CanUpdateProductSchedule_Sales] ||
    !userIsSales;
  const awaitingIPSApprovalCheck =
    availableActions[SheetAction.CanUpdateProductSchedule_Admin_SerialNumbers];

  const canViewDealerAdminFields =
    (userIsInternal || userIsAdmin) &&
    (sheetViewType == SheetViewType.IpsAdmin ||
      sheetViewType == SheetViewType.SupplierAdmin);

  const canAddProductSchedule =
    hasPermission(Targets.ProductSchedule, Actions.Create) &&
    awaitingDealerAdminApprovalCheck &&
    awaitingIPSApprovalCheck;

  const canRemoveProductSchedule =
    hasPermission(Targets.ProductSchedule, Actions.Delete) &&
    awaitingDealerAdminApprovalCheck &&
    awaitingIPSApprovalCheck;

  return (
    <Lookup
      lookup={productMeteredServiceLookup}
      lookupParams={{ sheetId }}
      render={({ loading, response }) => {
        if (loading) return <PageLoader />;
        if (!response) return null;
        const unitOptions = response
          ? response.map((m) => ({
              text: m.text,
              value: m.value,
              isLargeFormatItem: m.isLargeFormatItem,
            }))
          : [];

        return (
          <React.Fragment>
            <Form.Array
              field={field}
              arrayKey="id"
              propagateUpdates="always"
              fluid
              readOnly={readOnly}
              disabled={disabled}
            >
              {({ fields, arrayIndex, formFields }) => {
                checkAllAreLock(formFields);
                let allRows =
                  (toRemove
                    ? formFields.productScheduleItems
                    : formFields.removedProductScheduleItems) || [];
                const isLastRow = arrayIndex === allRows.length - 1;

                return (
                  <React.Fragment>
                    {arrayIndex == 0 && (
                      <div className="product-schedule-list-headings">
                        <PermissionCheck
                          target={Targets.ProductSchedule}
                          action={Actions.View}
                        >
                          <div className="ps-col ps-col-0">
                            <div>{t("Locked")}</div>

                            {hasLockPermission && (
                              <Icon
                                className={!areAllUnlocked && "gold-colour"}
                                name={areAllUnlocked ? "lock open" : "lock"}
                                onClick={() => {
                                  setLockAllSchedules(formFields);
                                }}
                              />
                            )}
                          </div>
                          <div className="ps-col">
                            <div className="ps-col-1">
                              {t("Applicable_items_ids")}
                            </div>
                          </div>
                          <div className="ps-col ps-col-2">
                            <div>{t("Manufacturer_Model_and_Description")}</div>
                          </div>
                          <div className="ps-col ps-col-3">
                            <div>{t("Type")}</div>
                          </div>
                          <div className="ps-col ps-col-4">
                            <div>{t("Identifier_Number")}</div>
                          </div>
                          <div className="ps-col ps-col-5">
                            <div>{t("Status")}</div>
                          </div>
                          <div className="ps-col ps-col-6 ">
                            <div>{t("Location_Postcode")}</div>
                          </div>
                          <div className="ps-col ps-col-7">
                            <div>{t("Consumables_Exclusive?")}</div>
                          </div>
                          <div className="ps-col ps-col-8">
                            <div>{t("Remote_Services?")}</div>
                          </div>
                          {canViewDealerAdminFields && (
                            <React.Fragment>
                              <div className="ps-col ps-col-9 green">
                                <div>{t("Meter_Reading_Code")}</div>
                              </div>
                              <div className="ps-col ps-col-9b green">
                                <div>{t("RRP")}</div>
                              </div>
                            </React.Fragment>
                          )}
                          <UserTypeCheck restrictTo={UserType.HQ}>
                            {sheetViewType == SheetViewType.IpsAdmin && (
                              <React.Fragment>
                                <div className="ps-col ps-col-10 blue">
                                  <div>{t("CFA_Reference")}</div>
                                </div>
                                <div className="ps-col ps-col-11 blue">
                                  <div>{t("IPS_Code")}</div>
                                </div>
                                <div className="ps-col ps-col-12 blue">
                                  <div>{t("IPS_Insurance")}</div>
                                </div>
                              </React.Fragment>
                            )}
                          </UserTypeCheck>
                        </PermissionCheck>

                        {(canAddProductSchedule ||
                          canRemoveProductSchedule) && (
                          <div className="ps-col ps-col-13" />
                        )}
                      </div>
                    )}
                    <div
                      className={classNames(
                        "product-schedule-list-item",
                        isLastRow && "last-row",
                      )}
                    >
                      <div className="ps-col ps-col-0">
                        <div>
                          {hasLockPermission &&
                          canLockViaUserArea(fields, currentUser) ? (
                            <Icon
                              className={fields.isLocked && "gold-colour"}
                              name={fields.isLocked ? "lock" : "lock open"}
                              onClick={() => {
                                setFieldLocking(fields, !fields.isLocked);
                                setRenderTrigger(!renderTrigger);
                              }}
                            />
                          ) : (
                            <Icon
                              className={fields.isLocked && "gold-colour"}
                              name={fields.isLocked ? "lock" : "lock open"}
                            />
                          )}
                        </div>
                      </div>
                      <div className="ps-col">
                        <div className="product-schedule-app-items ps-col-1">
                          <Form.Array
                            arrayKey="id"
                            autoAdd={true}
                            field="applicableItems"
                            propagateUpdates="always"
                            minLength={
                              fields.type == ProductScheduleType.Software
                                ? 0
                                : 1
                            }
                            readOnly={
                              updateSerialNumbersOnly ||
                              !salesFieldsCanBeUpdated ||
                              readOnly ||
                              fields.isLocked
                            }
                          >
                            {(internalArray) => {
                              let internalFields = { ...internalArray.fields };

                              return (
                                <ApplicableItemArray
                                  sheetId={sheetId}
                                  unitOptions={unitOptions}
                                  reinstate={reinstate}
                                  readOnly={
                                    updateSerialNumbersOnly ||
                                    !salesFieldsCanBeUpdated ||
                                    fields.isLocked
                                  }
                                  applicableItemField={internalFields}
                                  currentlySelected={fields.applicableItems.map(
                                    (m) => m.scheduleItemId,
                                  )}
                                  onDropdownOpen={onDropdownOpen}
                                  onDropdownClose={onDropdownClose}
                                ></ApplicableItemArray>
                              );
                            }}
                          </Form.Array>
                        </div>
                      </div>
                      <div className="ps-col ps-col-2">
                        <Form.Input
                          field="model"
                          required
                          renderReadOnly={(x) => <div>{x.value}</div>}
                          readOnly={
                            updateSerialNumbersOnly ||
                            !salesFieldsCanBeUpdated ||
                            readOnly ||
                            fields.isLocked
                          }
                        />
                      </div>

                      <div className="ps-col ps-col-3">
                        <ProductScheduleTypeDropdown
                          required
                          readOnly={
                            updateSerialNumbersOnly ||
                            !salesFieldsCanBeUpdated ||
                            readOnly ||
                            fields.isLocked
                          }
                          onFocus={onDropdownOpen}
                          onBlur={onDropdownClose}
                        />
                      </div>

                      <div className="ps-col ps-col-4">
                        <Form.Input
                          field="identifierNumber"
                          renderReadOnly={(x) => <div>{x.value}</div>}
                          readOnly={readOnly || fields.isLocked}
                        />
                      </div>

                      <div className="ps-col ps-col-5">
                        <ProductScheduleStatusDropdown
                          required
                          readOnly={
                            updateSerialNumbersOnly ||
                            !salesFieldsCanBeUpdated ||
                            readOnly ||
                            fields.isLocked
                          }
                          onFocus={onDropdownOpen}
                          onBlur={onDropdownClose}
                        />
                      </div>
                      <div className="ps-col ps-col-6">
                        <Form.Object field="clientAddress">
                          <Form.Object field="address">
                            <Form.Input readOnly required field="postcode" />
                          </Form.Object>
                        </Form.Object>
                        <div className="select-location-container">
                          {!readOnly && !fields.isLocked && (
                            <SelectLocationModal
                              setClientAddress={(newClientAddress) => {
                                const updatedItems = produce(
                                  productScheduleItems,
                                  (draftItems) => {
                                    draftItems[arrayIndex].clientAddress =
                                      newClientAddress;
                                  },
                                );
                                setProductScheduleItems(updatedItems);
                              }}
                              setAllClientAddresses={(newClientAddress) => {
                                const updatedItems = produce(
                                  productScheduleItems,
                                  (draftItems) => {
                                    draftItems.forEach((item) => {
                                      item.clientAddress = newClientAddress;
                                    });
                                  },
                                );
                                setProductScheduleItems(updatedItems);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="ps-col ps-col-7">
                        <Form.Dropdown
                          required
                          field="consumablesExclusive"
                          placeholder="Unset.."
                          options={[
                            { value: "true", text: "Yes" },
                            { value: "false", text: "No" },
                          ]}
                          renderReadOnly={(x) => (
                            <div>{x.value == "true" ? "Yes" : "No"}</div>
                          )}
                          readOnly={
                            updateSerialNumbersOnly ||
                            !salesFieldsCanBeUpdated ||
                            readOnly ||
                            fields.isLocked
                          }
                          onFocus={onDropdownOpen}
                          onBlur={onDropdownClose}
                        />
                      </div>
                      <div className="ps-col ps-col-8">
                        <div>
                          <Form.Dropdown
                            required
                            field="remoteServices"
                            placeholder="Unset.."
                            options={[
                              { value: "true", text: "Yes" },
                              { value: "false", text: "No" },
                            ]}
                            renderReadOnly={(x) => (
                              <div>{x.value == "true" ? "Yes" : "No"}</div>
                            )}
                            readOnly={
                              updateSerialNumbersOnly ||
                              !salesFieldsCanBeUpdated ||
                              readOnly ||
                              fields.isLocked
                            }
                            onFocus={onDropdownOpen}
                            onBlur={onDropdownClose}
                          />
                        </div>
                      </div>
                      {viewDealerAdminFields && (
                        <React.Fragment>
                          <div className="ps-col ps-col-9 text-left">
                            <Form.Array field="applicableItems" arrayKey="id">
                              {({ fields }) => (
                                <div className="meter-codes">
                                  <span className="text-green">
                                    {fields.itemCode}
                                  </span>
                                  {fields.meterData &&
                                    fields.meterData.length &&
                                    fields.meterData.map((x) => (
                                      <div key={x.id}>
                                        {x.meterCode && (
                                          <span className="text-green">
                                            {" - "}
                                            {x.meterCode}
                                          </span>
                                        )}
                                        {x.startMeterReading && (
                                          <span>
                                            {" - "}
                                            {x.startMeterReading}
                                          </span>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              )}
                            </Form.Array>
                          </div>
                          <div className="ps-col ps-col-9b text-green text-left">
                            <Form.Numeric
                              field="rrp"
                              renderReadOnly={(x) => <div>{x.value}</div>}
                              readOnly={
                                !ipsFieldsCanBeUpdated ||
                                readOnly ||
                                fields.isLocked
                              }
                              formatValue={true}
                              decimalPlaces={2}
                              minValue={0}
                              subLabel={process.env.MAJOR_CURRENCY}
                              subLabelPosition="left"
                            />
                          </div>
                        </React.Fragment>
                      )}

                      <UserTypeCheck restrictTo={UserType.HQ}>
                        {sheetViewType == SheetViewType.IpsAdmin && (
                          <React.Fragment>
                            <div className="ps-col ps-col-10">
                              <Form.Input
                                field="cfaRef"
                                renderReadOnly={(x) => <div>{x.value}</div>}
                                readOnly={
                                  !ipsFieldsCanBeUpdated ||
                                  readOnly ||
                                  fields.isLocked
                                }
                              />
                            </div>
                            <div className="ps-col ps-col-11">
                              <Form.Input
                                field="ipsCode"
                                renderReadOnly={(x) => <div>{x.value}</div>}
                                readOnly={
                                  !ipsFieldsCanBeUpdated ||
                                  readOnly ||
                                  fields.isLocked
                                }
                              />
                            </div>
                            <div className="ps-col ps-col-12">
                              <Form.Dropdown
                                field="ipsInsurance"
                                placeholder="Unset.."
                                options={[
                                  { value: "true", text: "Yes" },
                                  { value: "false", text: "No" },
                                ]}
                                renderReadOnly={(x) => (
                                  <div>{x.value == "true" ? "Yes" : "No"}</div>
                                )}
                                requiredError="IPS Insurance is required"
                                readOnly={
                                  !ipsFieldsCanBeUpdated ||
                                  readOnly ||
                                  fields.isLocked
                                }
                                onFocus={onDropdownOpen}
                                onBlur={onDropdownClose}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </UserTypeCheck>

                      {!disabled &&
                        !readOnly &&
                        (itemsCanBeAdded || itemsCanBeRemoved) && (
                          <div className="ps-col ps-col-13">
                            <ProductScheduleRemoveModal
                              nameOfRemoval="this_Product_Schedule_Item"
                              reinstate={reinstate}
                              isLocked={fields.isLocked}
                            />
                          </div>
                        )}
                    </div>
                  </React.Fragment>
                );
              }}
            </Form.Array>
          </React.Fragment>
        );
      }}
    />
  );
};

export default compose(withCurrentUser, withPermissions)(ProductScheduleItems);
