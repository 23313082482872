import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { ActionBar, Form, FormBuilder, ModalBuilder } from "@redriver/cinnamon";
import ProductScheduleItems from "./ProductScheduleItems";
import { getProductSchedules, updateProductSchedule } from "./actions";
import { IconButton } from "../../../../../shared/components/buttons";
import Add from "features/../../../shared/assets/blue-add.svg";
import {
  PermissionCheck,
  UserTypeCheck,
  withPermissions,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import {
  UserType,
  SheetAction,
  UserArea,
  SheetViewType,
} from "features/../../../shared/constants/enums";

import { Actions, Targets } from "constants/permissions";
import { ToggleSwitch } from "components/buttons";
import MeterReadingModal from "./MeterReadingModal";
import MeterCodeModal from "./MeterCodeModal";
import ProductScheduleStatusKey from "./ProductScheduleStatusKey";
import classNames from "classnames";
import { TranslatedModal } from "../../../../../shared/components/modals";

const newProductScheduleItem = (key, order) => {
  return {
    [key]: Date.now(),
    applicableItems: [],
    model: "",
    type: null,
    identifierNumber: "",
    order: order,
    status: null,
    postCode: "",
    consumablesExclusive: "",
    remoteServices: "",
    cFARef: "",
    iPSCode: "",
    iPSInsurance: "",
    isRemoved: false,
    isLocked: false,
  };
};

const ProductScheduleList = ({
  sheetId,
  currentUser,
  hasPermission,
  setProductScheduleNotViewable,
  sheetViewType,
  disabled,
}) => {
  const { t } = useTranslation("productSchedule");

  const [updateSerialNumbersOnly, setUpdateSerialNumbersOnly] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onDropdownOpen = (e) => {
    if (dropdownOpen) return;
    e.persist(); // required in order to ensure e.target is set
    setDropdownOpen(true);

    setTimeout(() => {
      const dropdownElement = document
        .getElementsByClassName("visible menu transition")[0]
        ?.getBoundingClientRect();
      const container = document.getElementsByClassName(
        "ps-content-body-container",
      )[0];
      const containerRectangle = container?.getBoundingClientRect();

      if (!dropdownElement) return;

      const dropdownOffsetPixels = 30;
      const isFirstFewLines = dropdownElement.top < 250;

      if (isFirstFewLines) return;

      if (dropdownElement.bottom < containerRectangle.bottom) return;

      if (dropdownElement && container) {
        container.scrollTo({
          top: dropdownElement.bottom,
          left: dropdownElement.left - dropdownOffsetPixels,
          behavior: "smooth",
        });
      }
    }, 250);
  };

  const onDropdownClose = () => setDropdownOpen(false);

  const dispatch = useDispatch();

  return (
    <FormBuilder
      submitAction={updateProductSchedule}
      submitParams={{ sheetId, updateSerialNumbersOnly }}
      loadParams={{ sheetId }}
      loadAction={getProductSchedules}
      loadTransform={(data) => ({
        ...data,
        remoteServices: data.productScheduleItems.forEach((x) => {
          x.remoteServices = x.remoteServices.toString();
        }),
        consumablesExclusive: data.productScheduleItems.forEach((x) => {
          x.consumablesExclusive = x.consumablesExclusive.toString();
        }),
      })}
      renderForm={(formProps, state, events) => {
        const userIsInternal = currentUser.userType === UserType.HQ;
        const userIsSales =
          !userIsInternal && currentUser.userArea === UserArea.AccountManager;
        const userIsAdmin =
          !userIsInternal && currentUser.userArea === UserArea.SupplierAdmin;

        const availableActions = formProps.value.availableActions || {};

        const awaitingDealerAdminApprovalCheck =
          availableActions[SheetAction.CanUpdateProductSchedule_Sales] ||
          !userIsSales;

        const awaitingIPSApprovalCheck =
          availableActions[
            SheetAction.CanUpdateProductSchedule_Admin_SerialNumbers
          ];

        const canViewDealerAdminFields =
          (userIsInternal || userIsAdmin) &&
          (sheetViewType == SheetViewType.IpsAdmin ||
            sheetViewType == SheetViewType.SupplierAdmin);

        const salesFieldsCanBeUpdated =
          hasPermission(Targets.ProductSchedule, Actions.Edit) &&
          awaitingDealerAdminApprovalCheck &&
          awaitingIPSApprovalCheck;

        const adminFieldsCanBeUpdated =
          hasPermission(Targets.ProductSchedule, Actions.DealerAdminEdit) &&
          awaitingIPSApprovalCheck;

        const ipsFieldsCanBeUpdated = hasPermission(
          Targets.ProductSchedule,
          Actions.IPSAdminEdit,
        );

        const canAddProductSchedule =
          hasPermission(Targets.ProductSchedule, Actions.Create) &&
          awaitingDealerAdminApprovalCheck &&
          awaitingIPSApprovalCheck;

        const canRemoveProductSchedule =
          hasPermission(Targets.ProductSchedule, Actions.Delete) &&
          awaitingDealerAdminApprovalCheck &&
          awaitingIPSApprovalCheck;

        const onlyAllowSerialNumberChanges =
          userIsAdmin && updateSerialNumbersOnly;

        const showToggleSwitch =
          userIsAdmin &&
          formProps.value.availableActions &&
          formProps.value.availableActions[
            SheetAction.CanUpdateProductSchedule_Admin_NonSerialNumbers
          ] &&
          formProps.value.availableActions[
            SheetAction.CanUpdateProductSchedule_Admin_SerialNumbers
          ];

        return (
          <div>
            <div className="product-schedule-wrapper content-container principal-list">
              <div>
                <Form
                  {...formProps}
                  disabled={disabled || formProps.disabled}
                  className="product-schedule-list"
                >
                  <div>
                    <div className="form-header">
                      <div>
                        {showToggleSwitch && (
                          <div className="toggle-container">
                            <ToggleSwitch
                              trueText={t("Update_Schedule_Numbers_only")}
                              falseText={t("Update_all")}
                              value={updateSerialNumbersOnly}
                              setValue={setUpdateSerialNumbersOnly}
                              className="product-schedule-toggle-switch"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classNames("ps-content-body-container")}>
                    <div className="non-removed-machines">
                      <ProductScheduleItems
                        field="productScheduleItems"
                        otherArray={formProps.value.removedProductScheduleItems}
                        toRemove={true}
                        sheetId={sheetId}
                        itemsCanBeAdded={false}
                        itemsCanBeRemoved={canRemoveProductSchedule}
                        viewDealerAdminFields={canViewDealerAdminFields}
                        updateSerialNumbersOnly={onlyAllowSerialNumberChanges}
                        salesFieldsCanBeUpdated={salesFieldsCanBeUpdated}
                        ipsFieldsCanBeUpdated={ipsFieldsCanBeUpdated}
                        sheetViewType={sheetViewType}
                        onDropdownOpen={onDropdownOpen}
                        onDropdownClose={onDropdownClose}
                        readOnly={disabled}
                        disabled={disabled}
                        availableActions={
                          formProps.value.availableActions || {}
                        }
                      />
                    </div>
                  </div>
                  <div className="product-schedule-add-and-modal">
                    {canAddProductSchedule && (
                      <React.Fragment>
                        <PermissionCheck
                          target={Targets.ProductSchedule}
                          action={Actions.Create}
                        >
                          <div>
                            <ActionBar.Item className="ps-action-bar-item">
                              <div className="sheet-step">
                                <Form.ArrayAdder
                                  className="ps-item-adder wizard-title-button"
                                  field="productScheduleItems"
                                  arrayKey="id"
                                  as={IconButton}
                                  icon={Add}
                                  content={t("New_Product")}
                                  populate={(k) => {
                                    const order =
                                      formProps.value.productScheduleItems
                                        .length + 1;
                                    return newProductScheduleItem(k, order);
                                  }}
                                />
                                <ProductScheduleStatusKey />
                              </div>
                            </ActionBar.Item>
                          </div>
                        </PermissionCheck>
                      </React.Fragment>
                    )}
                  </div>

                  <div className="product-schedule-modal-buttons">
                    <div>
                      {state.dirty ? (
                        <ModalBuilder
                          submitAction={() => {
                            setProductScheduleNotViewable();
                          }}
                          className="ps-save-button"
                          renderTrigger={(showModal) => (
                            <Button
                              primary
                              className="ps-save-button"
                              onClick={showModal}
                            >
                              {t("Close")}
                            </Button>
                          )}
                          renderModal={(modalProps) => (
                            <TranslatedModal.Confirmation
                              {...modalProps}
                              header={t("Close")}
                            >
                              <p> {t("confirm_close")}</p>
                            </TranslatedModal.Confirmation>
                          )}
                        />
                      ) : (
                        <Button
                          primary
                          className="ps-save-button"
                          onClick={() => setProductScheduleNotViewable()}
                        >
                          {t("Close")}
                        </Button>
                      )}
                    </div>
                    <UserTypeCheck restrictTo={UserType.HQ}>
                      {sheetViewType == SheetViewType.IpsAdmin && (
                        <React.Fragment>
                          <PermissionCheck
                            target={Targets.ProductSchedule}
                            action={Actions.SetMeterReading}
                          >
                            <div>
                              <MeterReadingModal
                                sheetId={sheetId}
                                onSubmitted={events.onRefresh}
                                saveRequired={state.dirty}
                                isProductScheduleValid={state.formValid}
                                disabled={disabled}
                                onSubmit={async () =>
                                  await dispatch(
                                    updateProductSchedule(formProps.value, {
                                      sheetId,
                                      updateSerialNumbersOnly,
                                    }),
                                  )
                                }
                              />
                            </div>
                          </PermissionCheck>
                          <PermissionCheck
                            target={Targets.ProductSchedule}
                            action={Actions.SetMeterCode}
                          >
                            <div>
                              <MeterCodeModal
                                sheetId={sheetId}
                                onSubmitted={events.onRefresh}
                                saveRequired={state.dirty}
                                isProductScheduleValid={state.formValid}
                                disabled={disabled}
                                onSubmit={async () =>
                                  await dispatch(
                                    updateProductSchedule(formProps.value, {
                                      sheetId,
                                      updateSerialNumbersOnly,
                                    }),
                                  )
                                }
                              />
                            </div>
                          </PermissionCheck>
                        </React.Fragment>
                      )}
                    </UserTypeCheck>
                    <div>
                      {(salesFieldsCanBeUpdated ||
                        adminFieldsCanBeUpdated ||
                        ipsFieldsCanBeUpdated ||
                        canRemoveProductSchedule ||
                        canAddProductSchedule) && (
                        <Button
                          primary
                          className="ps-save-button"
                          disabled={disabled}
                          onClick={() => {
                            events.onSubmit({ reload: true });
                          }}
                        >
                          {t("Save")}
                        </Button>
                      )}
                    </div>
                  </div>

                  {/*Removed Machines logic has been disabled for now*/}

                  {/*
                  <div className="removed-machines">
                    <h4>Removed Machines</h4>
                    <div
                      className={classNames(
                        "ps-content-body-container",
                        onDropdownOpen && "disable-overflow no-margin"
                      )}
                    >
                      <ProductScheduleItems
                        field="removedProductScheduleItems"
                        otherArray={formProps.value.productScheduleItems}
                        toRemove={false}
                        sheetId={sheetId}
                        reinstate={true}
                        readOnly={true}
                        itemsCanBeAdded={canAddProductSchedule}
                        itemsCanBeRemoved={false}
                        viewDealerAdminFields={canViewDealerAdminFields}
                        updateSerialNumbersOnly={onlyAllowSerialNumberChanges}
                        salesFieldsCanBeUpdated={salesFieldsCanBeUpdated}
                        ipsFieldsCanBeUpdated={ipsFieldsCanBeUpdated}
                        sheetViewType={sheetViewType}
                      />
                    </div>
                    <Form.If
                      condition={({ fields }) =>
                        fields.removedProductScheduleItems &&
                        fields.removedProductScheduleItems.length > 0
                      }
                    >
                      <ProductScheduleStatusKey className="product-schedule-list-item last-row" />
                    </Form.If>
                  </div>
                  */}
                </Form>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default compose(withCurrentUser, withPermissions)(ProductScheduleList);
