import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { ModalBuilder, Form, Table } from "@redriver/cinnamon";
import {
  PermissionCheck,
  UserTypeCheck,
} from "features/../../../shared/components/auth";
import { UserType } from "features/../../../shared/constants/enums";
import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { makeSheetLive } from "../actions";

const MakeSheetLive = ({
  sheet,
  scheduleReference,
  agreementReference,
  sheetActionRequired,
  onSubmitted,
}) => {
  const { t } = useTranslation("agreements", {
    keyPrefix: "SheetActions.MakeSheetLive",
  });
  const { sheetId, availableActions } = sheet;

  if (!availableActions[sheetActionRequired]) return null;

  return (
    <UserTypeCheck restrictTo={UserType.HQ}>
      <PermissionCheck target={Targets.Sheet} action={Actions.MakeLive}>
        <ModalBuilder
          withForm
          renderTrigger={(showModal) => (
            <Table.MenuItem onClick={showModal}>
              {t("TriggerText")}
            </Table.MenuItem>
          )}
          submitAction={makeSheetLive}
          submitParams={{ sheetId }}
          onSubmitted={onSubmitted}
          renderModal={(modalProps, formProps) => (
            <TranslatedModal.Add
              {...modalProps}
              header={t("HeaderText")}
              submitLabel={t("SubmitLabel")}
            >
              <Form {...formProps}>
                <Form.Date
                  instant
                  field="liveDate"
                  label={t("LiveDate_Label")}
                />
              </Form>

              <br />

              <p>{t("Message")}</p>
              <p>
                <Trans
                  t={t}
                  values={{ sheetName: sheet.sheetName }}
                  components={{ s: <strong /> }}
                >
                  ConfirmSheet_Label
                </Trans>
              </p>
              <p>
                <Trans
                  t={t}
                  values={{ scheduleReference }}
                  components={{ s: <strong /> }}
                >
                  ConfirmSchedule_Label
                </Trans>
              </p>
              <p>
                <Trans
                  t={t}
                  values={{ agreementReference }}
                  components={{ s: <strong /> }}
                >
                  ConfirmAgreement_Label
                </Trans>
              </p>
            </TranslatedModal.Add>
          )}
        />
      </PermissionCheck>
    </UserTypeCheck>
  );
};

export default MakeSheetLive;
