import React from "react";
import { connect } from "react-redux";
import { Format } from "@redriver/cinnamon";
import classNames from "classnames";
import {
  getSheetState,
  getSheetSummaryState,
  getCalculationData,
  getSheetRatesState,
} from "../selectors";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";
import {
  canCalculate,
  cannotCalcMessages,
  setSidePanelVisibility,
} from "./actions";
import { canSheetBeUpdated } from "./helpers";
import PredictedVolumeEntryModal from "./ComparisonSheet/PredictedVolumeEntryModal";
import ComparisonSnapshotModal from "./ComparisonSheet/ComparisonSnapshotModal";
import { useTranslation, Trans } from "react-i18next";
import { SaveConfirmationModal } from "./Shared";
import SheetWarnings from "features/Sheets/ManageSheet/Errors/SheetWarnings";
import PreviewGenerateDocument from "./PreviewGenerateDocument";
import { SheetType } from "features/../../../shared/constants/enums";

const Summary = ({
  calculationData,
  calculating,
  missingYieldBands,
  countedVolume,
  notCountedVolume,
  settlements,
  volumeReductionMinimum,
  nonVolumeReductionMinimum,
  totalScheduleManagedCountedVolume,
  totalMinimum,
  ratesContent,
  sheetData,
  sheetDetailsLoading,
  customerView,
  terminationDiscount,
  applicableFromUtc,
  priceDifference,
  systemScheduleItems,
  largeFormatGroups,
  sheetId,
  sheetCanBeUpdated,
  preventUpdates,
  isRewrite,
}) => {
  const { t } = useTranslation("sheetSummary");
  const canCalc = canCalculate(sheetData, calculationData);
  const showError =
    (!calculating && !sheetDetailsLoading && cannotCalcMessages.full) ||
    preventUpdates;

  let oldValuesPresent = systemScheduleItems?.some(
    (item) => item.oldMinQuarterly != null || item.oldCostPerUnit != null,
  );
  largeFormatGroups &&
    largeFormatGroups.forEach((item) => {
      item.items &&
        item.items.forEach((meter) => {
          if (meter.oldMinQuarterly != null || meter.oldCostPerUnit != null) {
            oldValuesPresent = true;
          }
        });
    });

  //only show predicted volume entry and  comparison snapshot buttons in customer view is sheet has snapshot.
  const showSnapshotButtons =
    (oldValuesPresent && customerView) || !customerView;

  return (
    <div className="sheet-summary">
      <div className="sheet-volume-reduction">
        <h4>{t("Summary")}</h4>
        {missingYieldBands && (
          <span className="calc-error">{t("Yield_Band_Error")}</span>
        )}
        {showError && <SheetWarnings calcOnly={false} />}
        <div className="summary-statement">
          <div className="summary-statement-text">
            {t("Min_Qtr_Contracted_Vol_Contributing_to_Managed_Vol")}
          </div>
          <h5>
            <InlineLoader size="small" isActive={calculating} />
            <Format.Number
              value={canCalc ? countedVolume : 0}
              decimalPlaces={0}
              thousandSeparator
              className={classNames(
                calculating && "summary-figure-loading",
                countedVolume < 0 && "negative",
              )}
            />
          </h5>
        </div>
        <div className="summary-statement">
          <div className="summary-statement-text">
            <Trans
              t={t}
              components={{ highlight: <span className="red-text bold" /> }}
            >
              Additional_Min_Qtr_Contracted_Vol_NOT_included_in_Managed_Volume
            </Trans>
          </div>
          <h5>
            <InlineLoader size="small" isActive={calculating} />
            <Format.Number
              value={canCalc ? notCountedVolume : 0}
              decimalPlaces={0}
              thousandSeparator
              className={classNames(
                calculating && "summary-figure-loading",
                notCountedVolume < 0 && "negative",
              )}
            />
          </h5>
        </div>
        <div className="summary-statement">
          <div className="summary-statement-text">
            {t("Total_Managed_Volume")}
          </div>
          <h5>
            <InlineLoader size="small" isActive={calculating} />
            <Format.Number
              value={
                canCalc
                  ? isRewrite
                    ? calculationData?.scheduleStability?.adjustmentSelection
                        ?.newTotalManagedVolume ??
                      totalScheduleManagedCountedVolume
                    : totalScheduleManagedCountedVolume
                  : 0
              }
              decimalPlaces={0}
              thousandSeparator
              className={classNames(
                calculating && "summary-figure-loading",
                totalScheduleManagedCountedVolume < 0 && "negative",
              )}
            />
          </h5>
        </div>
      </div>
      <div className="summary-agreement">
        <div>
          <span>{t("formal_acceptance_of_agreement")} </span>
          <InlineLoader isActive={calculating} className="paragraph" />
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={canCalc ? settlements : 0}
            className={classNames(
              calculating && "summary-figure-loading",
              settlements < 0 && "negative",
            )}
          />
          <span> {t("formal_acceptance_of_agreement_part2")}</span>
        </div>
      </div>
      <div className="summary-agreement">
        <div className="sheet-payment">
          <div>{t("Quarterly_Minimum_Payment")}</div>
          <div>{t("Contributing_to_Managed_Volume")}</div>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={canCalc ? volumeReductionMinimum : 0}
            className={classNames(
              "min-quarterly-payment",
              calculating && "summary-figure-loading",
              volumeReductionMinimum < 0 && "negative",
            )}
          />
        </div>
        <div className="sheet-payment">
          <div>
            <Trans
              t={t}
              components={{ highlight: <span className="red-text bold" /> }}
            >
              Quarterly_Minimum_Payment_NOT_Contributing_to_Managed_Volume
            </Trans>
          </div>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={canCalc ? nonVolumeReductionMinimum : 0}
            className={classNames(
              calculating && "summary-figure-loading",
              nonVolumeReductionMinimum < 0 && "negative",
            )}
          />
        </div>
        <div className="sheet-payment">
          <span>{t("Quarterly_Minimum_Payment")}</span>
          <InlineLoader size="tiny" isActive={calculating} />
          <Format.Number
            format={process.env.CURRENCY_CODE}
            value={canCalc ? totalMinimum : 0}
            className={classNames(
              calculating && "summary-figure-loading",
              totalMinimum < 0 && "negative",
            )}
          />
        </div>
        <div className="sheet-payment">
          <div>{t("Payment_Method")}</div>
          <strong>{ratesContent}</strong>
        </div>
        <div className="sheet-payment">
          <div>{t("Termination_Discount")}</div>
          <strong>
            <Format.Number
              decimalPlaces={2}
              format="percentage"
              value={canCalc ? terminationDiscount * 100 : 0}
              className={classNames(
                calculating && "summary-figure-loading",
                terminationDiscount < 0 && "negative",
              )}
            />
          </strong>
        </div>
        <div className="sheet-payment">
          <div>{t("Quarter_Date_Applicable")}</div>
          <strong>
            {applicableFromUtc ? (
              <Format.Date value={applicableFromUtc} format="DD MMM YYYY" />
            ) : (
              "-"
            )}
          </strong>
        </div>
        <div className="sheet-payment">
          <strong>
            <span>
              {t(`Quarterly_${priceDifference < 0 ? "Saving" : "Investing"}`)}
            </span>{" "}
            <Format.Number
              format={process.env.CURRENCY_CODE}
              value={canCalc ? priceDifference : null}
              className={classNames(
                calculating && "summary-figure-loading",
                priceDifference < 0 && "negative",
              )}
            />
          </strong>
        </div>
        {showSnapshotButtons && (
          <React.Fragment>
            <PredictedVolumeEntryModal disabled={!oldValuesPresent} />
            <ComparisonSnapshotModal
              disabled={!oldValuesPresent}
              sheetId={sheetId}
            />
          </React.Fragment>
        )}
        <PreviewGenerateDocument
          sheetId={sheetId}
          downloadOnly={false}
          renderTrigger={(onRender) => (
            <SaveConfirmationModal
              disabled={!canCalc}
              sheetId={sheetId}
              sheetCanBeUpdated={sheetCanBeUpdated}
              buttonContent={t("Preview_Document")}
              onSubmitted={onRender}
            />
          )}
        />
      </div>

      {/*#350686 - We currently do not need summary box */}
      {/* {!customerView && (
        <div className="summary-agreement incentive-info">
          <div className="incentive-row">
            <span>{t("Incentive")}</span>
            <InlineLoader size="tiny" isActive={calculating} />
            {!calculating && (
              <span className="number-formatter">
                {"once we have the field, this will be Format.Number" }-
              </span>
            )}
          </div>
          <div className="incentive-row">
            <span>{t("Schedule_Value_Remaining")}</span>
            <InlineLoader size="tiny" isActive={calculating} />
            {!calculating && (
              <span className="number-formatter">
                {"once we have the field, this will be Format.Number" }-
              </span>
            )}
          </div>
          <div className="incentive-row">
            <span>{t("Excess_Allocated_to_Schedule")}</span>
            <InlineLoader size="tiny" isActive={calculating} />
            {!calculating && (
              <span className="number-formatter">
                {"once we have the field, this will be Format.Number" }-
              </span>
            )}
          </div>
          <div className="incentive-row wrap">
            <span>
              <Trans t={t} components={{ s: <strong /> }}>
                Excess_Not_Allocated_to_Schedule
              </Trans>
            </span>
            <InlineLoader size="tiny" isActive={calculating} />
            {!calculating && (
              <span className="number-formatter">
               {"once we have the field, this will be Format.Number" }-
              </span>
            )}
          </div>
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { calculating, sheetData, sheetDetailsLoading, missingYieldBands } =
      getSheetState(state),
    {
      countedVolume,
      notCountedVolume,
      settlements,
      volumeReductionMinimum,
      nonVolumeReductionMinimum,
      totalScheduleManagedCountedVolume,
      totalMinimum,
      terminationDiscount,
    } = getSheetSummaryState(state),
    { applicableFromUtc, sheetType } = getSheetRatesState(state);

  return {
    calculating,
    missingYieldBands,
    countedVolume,
    notCountedVolume,
    settlements,
    volumeReductionMinimum,
    nonVolumeReductionMinimum,
    totalScheduleManagedCountedVolume,
    totalMinimum,
    terminationDiscount,
    sheetData,
    sheetDetailsLoading,
    calculationData: getCalculationData(state),
    applicableFromUtc,
    priceDifference: sheetData.comparisonData.priceDifference,
    systemScheduleItems: sheetData.systemScheduleItems,
    largeFormatGroups: sheetData.largeFormatGroups,
    preventUpdates: sheetData.preventUpdates,
    sheetCanBeUpdated: canSheetBeUpdated(sheetData),
    isRewrite:
      sheetType == SheetType.Rewrite ||
      sheetType == SheetType.RewriteNewMasterDocument,
    isCrystallised: sheetData?.isCrystallised,
  };
};

const actions = {
  setSidePanelVisibility,
};

export default connect(mapStateToProps, actions)(Summary);
