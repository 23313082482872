import React, { useMemo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import SheetStep from "../SheetStep";
import FmvSection from "./FmvSection";
import { Form, getAuthClaim } from "@redriver/cinnamon";
import {
  getSheetRestrictionsState,
  getSheetRatesState,
  getSheetIpsRatesState,
  getCalculationData,
  getSheetState,
  SheetTypeDropdownLookup,
  getSheetDataState,
} from "features/Sheets";
import {
  FixedInput,
  FixedInputResponsiveLabel,
  FixedString,
} from "components/forms";
import {
  PermissionCheck,
  withCurrentUser,
  withPermissions,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import {
  paymentMethods,
  SheetViewType,
  FunderType,
  UserType,
  SheetType,
} from "features/../../../shared/constants/enums";
import { useTranslation } from "react-i18next";

const ScheduleSetupStep = ({
  sheetId,
  onNextStep,
  onPreviousStep,
  displayPrevious,
  displayNext,
  displaySave,
  collapsed,
  toggleVisibility,
  reactRef,
  calculating,
  sheetDetailsSaving,
  sheetType,
  allowPaymentMethod,
  allowedPaymentMethods,
  paymentMethod,
  currentUser: { userType },
  customerToggle,
  displayCustomerToggle = false,
  sheetViewType,
  hasPermission,
}) => {
  const { t } = useTranslation("scheduleSetup");

  const translatedPaymentMethods = useMemo(
    () =>
      paymentMethods.map((x) => ({
        text: t(`enums:paymentMethod:${x.value}`),
        value: x.value,
      })),
    [paymentMethods],
  );

  const readOnly = useMemo(
    () => sheetViewType !== SheetViewType.IpsAdmin,
    [sheetViewType],
  );

  const allowForRewrite = useMemo(
    () =>
      sheetType == SheetType.Rewrite ||
      sheetType == SheetType.RewriteNewMasterDocument,
    [sheetViewType],
  );

  const yesNoOpts = useMemo(
    () => [
      { value: true, text: "Yes" },
      { value: false, text: "No" },
    ],
    [],
  );

  if (!hasPermission(Targets.Sheet, Actions.ViewScheduleSetup)) {
    return null;
  }

  return (
    <div ref={reactRef} className="step-container">
      <SheetStep
        title={t("Schedule_Set_Up")}
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        displayPrevious={displayPrevious}
        displayNext={displayNext}
        displaySave={displaySave}
        collapsed={collapsed}
        toggleVisibility={toggleVisibility}
        calculating={calculating}
        sheetId={sheetId}
        displayCustomerToggle={displayCustomerToggle}
        customerToggle={customerToggle}
        className="rates-step"
      >
        <Form.Object field="rates" propagateUpdates="always">
          <div className="rates-step">
            <div className="rates-step-grid">
              <div className="rates-step-field-row">
                <div className="rates-step-field-group group-1">
                  <SheetTypeDropdownLookup
                    className="sheet-type"
                    field="sheetType"
                    label={t("Sheet_Type")}
                    placeholder={t("Sheet_Type_placeholder")}
                    showErrors
                    fluid
                    optionField="sheetType"
                    disabled={readOnly}
                  />

                  <Form.Date
                    dateFormat="DD MMM YYYY"
                    instant
                    field="applicableFromUtc"
                    label={t("Applicable_From")}
                    showErrors
                    fluid
                    placeholder="&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/"
                    className="date-applicable-from"
                    disabled={readOnly}
                  />

                  <Form.Date
                    dateFormat="DD MMM YYYY"
                    instant
                    field="expiryDateUtc"
                    label={t("Expiry_Date")}
                    showErrors
                    fluid
                    placeholder="&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/"
                    className="date-applicable-from"
                    disabled={readOnly}
                  />
                  <Form.If condition={() => true}>
                    <FixedInput
                      label={
                        <FixedInputResponsiveLabel
                          label={t("Display_Settlement")}
                          className="sheet-radio-title green"
                        />
                      }
                      field="displaySettlement"
                      fluid
                      formatNumber
                      currency
                      className="medium"
                      placeholder={t("NIL")}
                      minAllowValue={0}
                    />
                    <FixedString
                      field="customerPOReference"
                      label={
                        <FixedInputResponsiveLabel
                          label={t("Customer_PO_Reference")}
                          className="sheet-radio-title green"
                        />
                      }
                      fluid
                      className="sheet-radio-title"
                    />
                  </Form.If>
                  {!process.env.TRADE_AS_IPS && (
                    <Form.Dropdown
                      options={yesNoOpts}
                      field="consumablesExclusive"
                      label={t("Consumables_Exclusive")}
                      disabled={readOnly}
                    />
                  )}
                </div>
              </div>
              <div className="rates-step-field-row">
                <div className="rates-step-field-group group-7">
                  <Form.TextArea
                    field="masterAdditionalTerms"
                    label={t("Master_Additional_Terms")}
                    placeholder={t("Master_Additional_Terms_placeholder")}
                    fluid
                    rows={2}
                    className="highlighted-input"
                    disabled={allowForRewrite || sheetDetailsSaving || readOnly}
                  />
                </div>
                <div className="rates-step-field-group group-8">
                  <Form.TextArea
                    field="scheduleAdditionalTerms"
                    label={t("Schedule_Additional_Terms")}
                    placeholder={t("Schedule_Additional_Terms_placeholder")}
                    fluid
                    rows={2}
                    className="highlighted-input"
                    disabled={readOnly}
                  />
                </div>
              </div>
              <PermissionCheck
                target={Targets.Sheet}
                action={Actions.ViewPaymentMethod}
              >
                <div className="rates-step-field-row">
                  <div className="rates-step-field-group group-4">
                    {userType === UserType.HQ || allowPaymentMethod ? (
                      <React.Fragment>
                        <div className="sheet-radio-title">
                          <p>{t("Payment_Method")}</p>
                        </div>
                        <div className="payment-method-radio">
                          <Form.RadioGroup
                            width={12}
                            inline={true}
                            options={
                              allowedPaymentMethods
                                ? translatedPaymentMethods.filter((x) =>
                                    allowedPaymentMethods.includes(x.value),
                                  )
                                : translatedPaymentMethods
                            }
                            field="paymentMethod"
                            className="required-field sheet-radio-group payment"
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <Form.Input
                        label={t("Payment_Method")}
                        inline={true}
                        readOnly
                        renderReadOnly={() => (
                          <span>
                            {t(`enums:paymentMethod:${paymentMethod}`)}
                          </span>
                        )}
                      />
                    )}
                  </div>
                </div>
              </PermissionCheck>
              <div className="rates-step-field-row">
                <div className="rates-step-field-group group-10">
                  <FmvSection funderType={FunderType.Funder} />
                  <FmvSection funderType={FunderType.Dealer} />
                  <FmvSection funderType={FunderType.CoFunded} />
                </div>
              </div>
            </div>
          </div>
        </Form.Object>
      </SheetStep>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { applicableFromUtc, sheetType, paymentMethod } =
    getSheetRatesState(state);
  const calculationData = getCalculationData(state);
  const fmvCalculationData = calculationData
    ? calculationData.breakdown.fmvDetails
    : [];
  const {
    unlockAllowedTieredToner,
    unlockDisableMinVolsLogic,
    unlockIncludeUpgradeClause,
  } = getSheetIpsRatesState(state);
  const { calculating, sheetDetailsSaving, sheetViewType } =
    getSheetState(state);

  const { rates } = getSheetDataState(state);

  const { allowPaymentMethod, allowedPaymentMethods } =
    getSheetRestrictionsState(state) || {};

  return {
    applicableFromUtc,
    unlockAllowedTieredToner,
    unlockDisableMinVolsLogic,
    unlockIncludeUpgradeClause,
    fmvCalculationData,
    calculating,
    sheetDetailsSaving,
    sheetType,
    allowPaymentMethod,
    allowedPaymentMethods,
    paymentMethod,
    sheetViewType,
    rates,
    userArea: getAuthClaim(state, "userArea"),
  };
};

export default compose(
  connect(mapStateToProps),
  withCurrentUser,
)(withPermissions(ScheduleSetupStep));
