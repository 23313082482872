import React from "react";
import { useTranslation } from "react-i18next";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { resetSheetState } from "../actions";

const ResetSheetState = ({ sheet, onSubmitted, sheetActionRequired }) => {
  const { t } = useTranslation("agreements");

  const { sheetId, sheetName, availableActions } = sheet;
  if (!availableActions[sheetActionRequired]) return null;

  return (
    <ModalBuilder
      withForm
      submitAction={resetSheetState}
      submitParams={{ sheetId }}
      onSubmitted={onSubmitted}
      renderError={false}
      renderSubmitting={false}
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>
          {t("SheetActions.ResetSheetState.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header={t("SheetActions.ResetSheetState.HeaderText")}
        >
          {t("SheetActions.ResetSheetState.ConfirmationText", {
            sheetName: sheetName,
          })}
        </TranslatedModal.Confirmation>
      )}
    />
  );
};

export default ResetSheetState;
