import React from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import {
  AuthenticatedSwitch,
  PrivateRoute,
  PublicRoute,
  NavigationLogger,
  ResponsiveScreen,
  AuthenticatedApp,
} from "@redriver/cinnamon";
import { PwaHandler } from "features/../../../shared/components/system";
import { EnvironmentBanner } from "components/banners";
import { SystemRoutes } from "constants/routes";
import LoginPage from "./system/LoginPage";
import LogoutPage from "./system/LogoutPage";
import AccountManagerLogoutPage from "./system/AccountManagerLogoutPage";
import UserSetupPage from "./system/UserSetupPage";
import EmailVerificationPage from "./system/EmailVerificationPage";
import PasswordForgottenPage from "./system/PasswordForgottenPage";
import PasswordResetPage from "./system/PasswordResetPage";
import PrincipalApp from "./PrincipalApp";
import "styles/index.scss";
import AuthenticationPage from "./system/AuthenticationPage";
/**
 * Top-level app routes only
 * Define sub-path routes inside top-level page components
 */
class Shell extends React.Component {
  render() {
    return (
      <AuthenticatedApp>
        <NavigationLogger />
        <ResponsiveScreen />
        <EnvironmentBanner />
        <PwaHandler appName="Amethyst" />
        <AuthenticatedSwitch>
          {/* system routes */}
          <Route exact path={SystemRoutes.Login} component={LoginPage} />
          <Route exact path={SystemRoutes.Logout} component={LogoutPage} />
          <Route
            path={SystemRoutes.Authenticate}
            component={AuthenticationPage}
          />
          <Route
            exact
            path={SystemRoutes.AccountManagerLogout}
            component={AccountManagerLogoutPage}
          />
          <Route
            exact
            path={SystemRoutes.SetupUser}
            component={UserSetupPage}
          />
          <Route
            exact
            path={SystemRoutes.VerifyEmail}
            component={EmailVerificationPage}
          />
          <PublicRoute
            exact
            path={SystemRoutes.ForgottenPassword}
            component={PasswordForgottenPage}
          />
          <PublicRoute
            exact
            path={SystemRoutes.ResetPassword}
            component={PasswordResetPage}
          />
          {/* app routes */}
          <PrivateRoute
            component={PrincipalApp}
            redirect={SystemRoutes.Login}
          />
        </AuthenticatedSwitch>
      </AuthenticatedApp>
    );
  }
}

const actions = {};

export default withRouter(connect(null, actions)(Shell));
