import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import classNames from "classnames";
import ipsLogo from "assets/logos/ipsLogo-442x442.png";

const SystemPageContent = ({ children, compact, className }) => {
  const { t } = useTranslation('system');
  return(  
    <div className={classNames("system-page-content", { compact }, className)}>
      <Grid >
        <Grid.Column 
          computer={8}
          mobile={16}
          tablet={16}
          largeScreen={6}
          widescreen={6}
        >
          <section className="content-foreground">
            <div className="content">
            <Link to={AppRoutes.Root}>
            <Image
                  centered
                  src={ipsLogo}
                  className="logo"
                  alt="IP Syndicate - Principal"
                  title="IP Syndicate - Principal"
                />
            </Link>
            {children}
            <div className="flex-spacer" />
              <div className="sys-actions">
                <Link to={AppRoutes.Root}>{t('Privacy_Policy')}</Link>
                <span>{t('and')}</span>
                <Link to={AppRoutes.Root}>{t('Terms_of_Service')}</Link>
              </div>
              <div className="standoff" />
              </div>
          </section>
        </Grid.Column>
      </Grid>
      <div className="system-page-backdrop">
        
      </div>
    </div>
)};

export default SystemPageContent;
