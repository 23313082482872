import React from "react";
import { Form, Format } from "@redriver/cinnamon";
import { FixedInput, FixedBoolean } from "components/forms";
import classNames from "classnames";
import { useTranslation, Trans } from "react-i18next";

const CustomerStepScheduleItem = ({ fields, sheetDetailsSaving }) => {
  const { t } = useTranslation("customerView");

  const majorCurrency = process.env.MAJOR_CURRENCY;
  const minorCurrency = process.env.MINOR_CURRENCY;

  return (
    <React.Fragment>
      <div className="customer-view-item">
        <div className="col col-1 bold centered">
          <Form.Input field="displayCode" readOnly />
        </div>
        <div className="col col-2">
          <Form.Object field="unit">
            <Form.Input field="unitName" readOnly />
          </Form.Object>
        </div>
        <div className="col col-3 centered">
          <FixedBoolean
            field="includeManagedVol"
            toggle
            className="toggle"
            hideIndicator={true}
            disabled={
              (fields.unit && fields.unit.alwaysUnmanaged) || sheetDetailsSaving
            }
          />
        </div>
        <div className="col col-4 centered">
          <FixedBoolean
            field="tonerExclusive"
            toggle
            className="toggle"
            hideIndicator={true}
            readOnly
            renderReadOnly={(x) => <div>{x.value.value ? "Yes" : "No"}</div>}
          />
        </div>
        <div className="col col-5 centered">
          <FixedBoolean
            field="tieredToner"
            toggle
            className="toggle"
            hideIndicator={true}
            readOnly
            renderReadOnly={(x) => <div>{x.value.value ? "Yes" : "No"}</div>}
          />
        </div>
        <div className="col col-6 centered quart-align">
          <FixedInput
            field="minQuarterly"
            inline
            width={9}
            required
            hideIndicator={true}
            formatNumber
            decimalPlaces={0}
          />
        </div>
        <div className="col col-7 customer-view-cpu centered">
          <strong
            className={classNames(
              "cost-per-unit",
              "calculable-field",
              fields.costPerUnit < 0 && "negative"
            )}
          >
            <div className="customer-view-at">{t("System_Schedule_at")}</div>
            {process.env.USE_MINOR_CURRENCY ? (
              <Format.Number
                value={fields.costPerUnit || 0}
                decimalPlaces={{ min: 0, max: 3 }}
                suffix={process.env.MINOR_CURRENCY}
              />
            ) : (
              <Format.Number
                value={fields.costPerUnit || 0}
                decimalPlaces={{ min: 0, max: 5 }}
                prefix={process.env.MAJOR_CURRENCY}
              />
            )}
          </strong>
        </div>
      </div>
      {fields.tieredToner.value && (
        <div className="customer-view-item height-centred customer-view-tiers">
          <div className="col-tier-1"></div>
          <div className="col-tier-2">
            <Trans
              t={t}
              values={{
                tier1UpperBoundary: fields.tier1UpperBoundaryPercentage.value,
              }}
              components={{
                s: <strong className="customer-view-tier"></strong>,
              }}
            >
              System_Schedule_Tier1_Percentage
            </Trans>
          </div>
          <div className="col-tier-3">
            <Trans
              t={t}
              values={{
                tier1UpperBoundary: fields.tier1UpperBoundaryPercentage.value,
                tier2UpperBoundary: fields.tier2UpperBoundaryPercentage.value,
                tier2Value: fields.tier2Adds.value,
                minorCurrency: minorCurrency,
                majorCurrency: majorCurrency,
              }}
              components={{
                s: <strong className="customer-view-tier"></strong>,
              }}
            >
              {process.env.USE_MINOR_CURRENCY
                ? "System_Schedule_Tier2_Percentage_Minor"
                : "System_Schedule_Tier2_Percentage_Major"}
            </Trans>
          </div>
          <div className="col-tier-4">
            <Trans
              t={t}
              values={{
                tier2UpperBoundary: fields.tier2UpperBoundaryPercentage.value,
                tier3Value: fields.tier3Adds.value,
                minorCurrency: minorCurrency,
                majorCurrency: majorCurrency,
              }}
              components={{
                s: <strong className="customer-view-tier"></strong>,
              }}
            >
              {process.env.USE_MINOR_CURRENCY
                ? "System_Schedule_Tier3_Percentage_Minor"
                : "System_Schedule_Tier3_Percentage_Major"}
            </Trans>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomerStepScheduleItem;
