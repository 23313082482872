import { apiPost, notifySuccess } from "@redriver/cinnamon";

const ActionTypes = {
  CustomerApprovalSubmit: "SHEETS/CUSTOMER_APPROVAL_SUBMIT",
};

export const sendForCustomerApproval =
  (formData = {}, params = {}) =>
  async (dispatch) => {
    let { sheetId } = params,
      response = await dispatch(
        apiPost(
          ActionTypes.CustomerApprovalSubmit,
          `sheets/${sheetId}/submit/customer-approval`,
          { ...formData, sheetId }
        )
      );
    response &&
      response.success &&
      dispatch(
        notifySuccess("Sheet successfully submitted for Customer approval")
      );
    return response;
  };
