import React from "react";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { SheetState } from "constants/sheetState";
import { Icon } from "semantic-ui-react";
import { TranslatedModal } from "features/../../../shared/components/modals";

const statesToRender = [
  SheetState.PreLive_Draft,
  SheetState.PreLive_WithCustomer_Docusign,
  SheetState.PreLive_WithSupplierAdmin_Docusign,
  SheetState.PreLive_WithSupplierSignatory_Docusign,
  SheetState.PreLive_WithIPSAdmin_Docusign,
  SheetState.PreLive_WithIPSSignatory_Docusign,
  SheetState.PreLive_IPS_Approved,
  SheetState.Live,
];

const CircleAndText = ({ text, isEnd = false, isCompleted = false }) => {
  return (
    <div className="sheet-state-progress-view">
      <div className="state-progress-shape">
        <div
          className={classNames(
            "progress-circle",
            isCompleted ? "completed" : "not-completed",
          )}
        >
          <p className="draft-text">
            {isCompleted ? (
              <Icon name="check" />
            ) : (
              <Icon name="circle outline" />
            )}
          </p>
        </div>
        <p className="draft">{text}</p>
      </div>
      {!isEnd && (
        <div
          className={classNames(
            "vertical-line",
            isCompleted ? "completed" : "not-completed",
          )}
        />
      )}
    </div>
  );
};

const SheetStateProgressView = ({ sheet }) => {
  const { t } = useTranslation("agreements");
  const { t: tState } = useTranslation("enums", { keyPrefix: "SheetState" });

  return (
    <ModalBuilder
      withForm
      renderTrigger={(trigger) => (
        <Table.MenuItem onClick={trigger}>
          {t("SheetActions.ViewProgress.TriggerText")}
        </Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Information
          {...modalProps}
          header={t("SheetActions.ViewProgress.HeaderText")}
          cancelDisabled={true}
          submitLabel="Close"
          cancelLabel=""
        >
          {statesToRender.map((sheetState, index) => (
            <CircleAndText
              text={tState(
                Object.entries(SheetState).find((x) => x[1] == sheetState)[0],
              )}
              isCompleted={sheetState <= sheet.stateDescriptionNumber}
              isEnd={
                index == statesToRender.length - 1 &&
                sheet.stateDescriptionNumber == SheetState.Live
              }
              key={index}
            />
          ))}

          {sheet.stateDescriptionNumber >= SheetState.Retired && (
            <React.Fragment>
              <CircleAndText
                text={tState(
                  Object.entries(SheetState).find(
                    (x) => x[1] == SheetState.Retired,
                  )[0],
                )}
                isCompleted={SheetState.Retired <= sheet.stateDescriptionNumber}
                isEnd={sheet.stateDescriptionNumber < SheetState.Voided}
              />
              {sheet.stateDescriptionNumber >= SheetState.Voided && (
                <CircleAndText
                  text={tState(
                    Object.entries(SheetState).find(
                      (x) => x[1] == SheetState.Voided,
                    )[0],
                  )}
                  isCompleted={
                    SheetState.Voided <= sheet.stateDescriptionNumber
                  }
                  isEnd={true}
                />
              )}
            </React.Fragment>
          )}
        </TranslatedModal.Information>
      )}
    />
  );
};

export default SheetStateProgressView;
