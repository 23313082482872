import React from "react";
import { connect } from "react-redux";
import { ModalBuilder, useFormField } from "@redriver/cinnamon";
import {
  newLargeFormatScheduleGroup,
  canCalculate,
  downloadLargeFormatPrinting,
  DownloadConfirmationModal,
} from "features/Sheets/ManageSheet";
import {
  fillLargeFormatCreateModal,
  calculateForm,
  pauseCalculation,
} from "../../actions";
import { clearLargeFormatCreateModal } from "features/../../../shared/features/Settings/LargeFormat/actions";
import LargeFormatModalDetails from "./LargeFormatModalDetails";
import {
  PermissionCheck,
  UserTypeCheck,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import {
  UserType,
  SheetViewType,
} from "features/../../../shared/constants/enums";
import {
  getSheetState,
  getSheetDataState,
  getSheetLargeFormatScheduleState,
  getCalculationData,
} from "features/Sheets";
import { Button } from "semantic-ui-react";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { canSheetBeUpdated } from "../../helpers";

const ScheduleStepEditLargeFormat = ({
  sheetId,
  largeFormatGroups,
  sheetData,
  clearLargeFormatCreateModal,
  fillLargeFormatCreateModal,
  largeFormatEditIndex,
  editModalTrigger,
  setEditModalTrigger,
  largeFormatCreateModal,
  largeFormatDefaults,
  setLfEditModalLoaded,
  lfEditModalLoaded,
  sheetViewType,
  preventUpdates,
  calculationData,
  sheetCanBeUpdated,
  calculateForm,
  pauseCalculation,
}) => {
  const [existingItems, setExistingItems] = useFormField("largeFormatGroups");

  //when creating a pdf we must run this function to ensure the data in
  //redux is up to date before we save the sheet and eventually download the PDF
  const saveModalDataWithoutClosing = async () => {
    await pauseCalculation();
    saveModalData(false);
  };
  const saveModalData = (close = true) => {
    let editedItem = newLargeFormatScheduleGroup(
      "id",
      largeFormatCreateModal,
      largeFormatDefaults,
      largeFormatGroups,
      true,
    );

    let y = existingItems.slice();
    y.splice(largeFormatEditIndex, 1, editedItem);
    setExistingItems(y);
    if (close) {
      setLfEditModalLoaded(false);
      setEditModalTrigger(false);
    }
  };

  const onDownloadPdfSave = () => {
    //first we must save any changes to the modal that have been made since it was opened.
    saveModalData();
    fillLargeFormatCreateModal(largeFormatEditIndex, sheetId);
    setLfEditModalLoaded(true);
    setEditModalTrigger(true);
  };

  return (
    <ModalBuilder
      onCancelled={() => {
        clearLargeFormatCreateModal();
        setLfEditModalLoaded(false);
        setEditModalTrigger(false);
        calculateForm(false);
      }}
      visible={editModalTrigger}
      onLoaded={async () => {
        await fillLargeFormatCreateModal(largeFormatEditIndex, sheetId);
        setLfEditModalLoaded(true);
      }}
      onSubmit={() => {
        saveModalData();
        clearLargeFormatCreateModal();
        calculateForm(false);
      }}
      renderModal={(modalProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header="Edit Large Format"
          className="large-format-edit-modal"
        >
          <LargeFormatModalDetails
            sheetId={sheetId}
            lfEditModalLoaded={lfEditModalLoaded}
            editMode
            sheetViewType={sheetViewType}
          />
          <div className="custom-action-container">
            <PermissionCheck target={Targets.Sheet} action={Actions.Download}>
              <UserTypeCheck restrictTo={UserType.HQ}>
                <PermissionCheck target={Targets.Sheet} action={Actions.Edit}>
                  {(sheetViewType === SheetViewType.IpsAdmin ||
                    sheetViewType === SheetViewType.SupplierAdmin) && (
                    <DownloadConfirmationModal
                      onSubmitFunction={saveModalDataWithoutClosing}
                      sheetCanBeUpdated={sheetCanBeUpdated}
                      disabled={!canCalculate(sheetData, calculationData)}
                      sheetId={sheetId}
                      sheetDownloadFunction={downloadLargeFormatPrinting}
                      isAdmin={true}
                      buttonContent="Print Admin Version"
                      onSaved={onDownloadPdfSave}
                    />
                  )}
                </PermissionCheck>
              </UserTypeCheck>
              <DownloadConfirmationModal
                onSubmitFunction={saveModalDataWithoutClosing}
                sheetCanBeUpdated={sheetCanBeUpdated}
                disabled={!canCalculate(sheetData, calculationData)}
                sheetId={sheetId}
                sheetDownloadFunction={downloadLargeFormatPrinting}
                isAdmin={false}
                buttonContent="Print Version"
                onSaved={onDownloadPdfSave}
              />
            </PermissionCheck>

            <Button negative onClick={modalProps.onCancel}>
              Cancel
            </Button>
            <Button
              positive
              onClick={modalProps.onSubmit}
              disabled={
                preventUpdates ||
                largeFormatCreateModal.printerId == null ||
                (largeFormatCreateModal.lfRollMedia || []).some(
                  (pm) => !pm.paperWidthId || !pm.paperInclusiveId,
                )
              }
            >
              Update
            </Button>
          </div>
        </TranslatedModal.Edit>
      )}
    />
  );
};

const mapStateToProps = (state, params) => {
  const largeFormatItem =
    getSheetLargeFormatScheduleState(state)[params.largeFormatEditIndex] || [];
  const { largeFormatCreateModal } = getSheetDataState(state),
    { defaults, largeFormatGroups } = getSheetDataState(state);
  const sheetState = getSheetState(state);
  return {
    largeFormatGroups,
    sheetData: sheetState.sheetData,
    largeFormatItem,
    largeFormatCreateModal,
    lfPrinterConfig: largeFormatCreateModal.lfPrinterConfig,
    largeFormatDefaults: defaults.largeFormatDefaults,
    impersonatedUser: sheetState.sheetData.impersonatedUser,
    preventUpdates: sheetState.sheetData.preventUpdates,
    sheetCanBeUpdated: canSheetBeUpdated(getSheetDataState(state)),
    calculationData: getCalculationData(state),
  };
};

const actions = {
  fillLargeFormatCreateModal,
  clearLargeFormatCreateModal,
  calculateForm,
  pauseCalculation,
};

export default connect(mapStateToProps, actions)(ScheduleStepEditLargeFormat);
