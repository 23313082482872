import {
  calcScheduleItemCode,
  shouldPreserveScheduleItemCodes,
} from "../../helpers";

export function updateSchItems(newSheetData, unitData) {
  function update(schItem, arrayIndex) {
    let unitId = schItem.unit.value;
    let matchedUnit = unitData.find((x) => x.id === unitId);
    if (!matchedUnit) return;

    //update unit code and name
    schItem.unit.unitCode = matchedUnit.code;
    schItem.unit.unitName = matchedUnit.name;
    schItem.unit.overwritable = matchedUnit.overwritable;
    schItem.unit.alwaysUnmanaged = matchedUnit.alwaysUnmanaged;
    schItem.unit.alwaysManaged = matchedUnit.alwaysManaged;

    if (
      !schItem.displayCode ||
      !shouldPreserveScheduleItemCodes(newSheetData)
    ) {
      schItem.displayCode = calcScheduleItemCode(
        schItem,
        newSheetData.systemScheduleItems
      );
    }

    schItem.order = arrayIndex; //we want to strictly preserve the FE display order
  }

  newSheetData.systemScheduleItems.filter((x) => x).forEach(update);
}
