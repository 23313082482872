import moment from "moment";
import { produce } from "immer";
import {
  updateIncomingSchItems,
  addDependencies,
  updateSchItems,
  updateLfItems,
  setLockedFields,
  calculateTotals,
  calculateTotalCosts,
  checkSectionsCompleted,
  setLargeFormatCreateModalData,
  updateQuarterlyServicesOnServiceChange,
  initialSheetState as initialState,
} from "features/Sheets/ManageSheet/ReducerHelpers";
import { getWizardSplitTotals } from "features/Sheets/ManageSheet/helpers";
import { calculateComparisonSheetData } from "features/Sheets/ManageSheet/ComparisonSheet/Utils";

export const updateSheetData = (state, action) => {
  const sd = produce(action.applyChanges(state.sheetData), () => {});

  return produce({ ...state, sheetData: sd }, (draftState) => {
    const getCalculationData = (sheetsState) => sheetsState.rawCalculation;

    let newSheetData = draftState.sheetData;

    newSheetData.lastUpdated = Date.now(); // used to help detect changes to data, and cause re-renders
    newSheetData.isDirty = true;

    let { sheetData } = action;
    switch (action.field) {
      case "incomingSchItem":
        if (sheetData && sheetData.field == "unit") {
          updateIncomingSchItems(newSheetData, action.unitData);
        }
        break;
      case "systemScheduleItems":
        addDependencies(newSheetData, action.unitData, state.sheetData);
        updateSchItems(newSheetData, action.unitData);
        break;
      case "largeFormatGroups":
        updateLfItems(
          newSheetData,
          state.sheetData,
          action.largeFormatUnitData,
        );
        break;
      case "services":
        updateQuarterlyServicesOnServiceChange(
          state.sheetData.services,
          newSheetData.services,
          action.serviceData,
        );
        break;
      default:
        break;
    }

    // If applicable from is being updated and an expiry date is set, ensure expiry is not before the applicable from date
    if (
      sheetData &&
      sheetData.applicableFromUtc &&
      newSheetData.rates.applicableFromUtc &&
      newSheetData.rates.expiryDateUtc &&
      moment(newSheetData.rates.expiryDateUtc).isBefore(
        moment(newSheetData.rates.applicableFromUtc),
      )
    ) {
      newSheetData.rates.expiryDateUtc = newSheetData.rates.applicableFromUtc;
    }

    // If locked rates fields in IP Syndicate rates are set, update Rates section
    setLockedFields(newSheetData.ipsRates, newSheetData.rates);

    const {
      funder,
      cofunded,
      dealer,
      funderSelected,
      cofundedSelected,
      dealerFunderSelected,
    } = newSheetData.capital;

    const funderTotals = funderSelected.value
      ? calculateTotals(funder)
      : getWizardSplitTotals();

    const coFundedTotals = cofundedSelected.value
      ? calculateTotals(cofunded)
      : getWizardSplitTotals();

    const dealerTotals = dealerFunderSelected.value
      ? calculateTotals(dealer)
      : getWizardSplitTotals();

    const funderTotalCosts = newSheetData.capital.funderSelected
      ? calculateTotalCosts(funder)
      : 0;

    const coFundedTotalCosts = newSheetData.capital.cofundedSelected
      ? calculateTotalCosts(cofunded)
      : 0;

    const dealerTotalCosts = newSheetData.capital.dealerFunderSelected
      ? calculateTotalCosts(dealer)
      : 0;

    const { userType, userArea } = action;

    const sectionsCompleted = checkSectionsCompleted(newSheetData);

    setLargeFormatCreateModalData(state.sheetData, newSheetData);

    newSheetData.capital.cofundedTotal = coFundedTotals.getTotal(
      userType,
      userArea,
    );
    newSheetData.capital.funderTotal = funderTotals.getTotal(
      userType,
      userArea,
    );
    newSheetData.capital.dealerTotal = dealerTotals.getTotal(
      userType,
      userArea,
    );
    newSheetData.capital.cofundedTotalCosts = coFundedTotalCosts;
    newSheetData.capital.funderTotalCosts = funderTotalCosts;
    newSheetData.capital.dealerTotalCosts = dealerTotalCosts;

    newSheetData.comparisonData =
      calculateComparisonSheetData(newSheetData, getCalculationData(state)) ||
      initialState.comparisonData;

    draftState.capitalContent = getWizardSplitTotals(
      funderTotals.salesperson +
        dealerTotals.salesperson +
        coFundedTotals.salesperson,
      funderTotals.dealerAdmin +
        dealerTotals.dealerAdmin +
        coFundedTotals.dealerAdmin,
      funderTotals.ipsAdmin + dealerTotals.ipsAdmin + coFundedTotals.ipsAdmin,
    );

    draftState.scheduleContent =
      newSheetData.systemScheduleItems.length +
      newSheetData.largeFormatGroups
        .map((x) =>
          [x.printerMeter, x.scanMeter, x.otherMeter]
            .concat(x.paperMeters)
            .filter((x) => x),
        )
        .flat().length;

    draftState.ratesCompleted = sectionsCompleted.rates;
    draftState.capitalCompleted = sectionsCompleted.capital;
    draftState.servicesCompleted = sectionsCompleted.services;
    draftState.scheduleCompleted = sectionsCompleted.schedule;
    draftState.scheduleStabilityCompleted = sectionsCompleted.scheduleStability;
    draftState.paperCompleted = sectionsCompleted.paper;
    draftState.paperHasContent = sectionsCompleted.paperHasContent;
    draftState.largeFormatCompleted = sectionsCompleted.largeFormat;
  });
};
