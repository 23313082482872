import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { ModalBuilder } from "@redriver/cinnamon";
import { TranslatedModal } from "features/../../../shared/components/modals";
import {
  usePermission,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import { useTranslation } from "react-i18next";
import InlinePdf from "features/../../../shared/components/display/InlinePdf";
import {
  downloadSheetContract,
  getSheetContract,
  downloadPrintPreview,
} from "./actions";

import { Actions, Targets } from "constants/permissions";

import { useDispatch, connect } from "react-redux";
import { SendForApproval } from "features/Agreements/AgreementsList/SheetActions/SendForApproval";
import { SheetAction } from "features/../../../shared/constants/enums";

import { compose } from "redux";
import { getSheetDataState } from "../selectors";

const PreviewGenerateDocument = ({
  sheetId,
  disableTrigger,
  disableSubmit,
  onSubmitted,
  renderTrigger,
  sheet,
  downloadOnly,
}) => {
  const { t } = useTranslation("sheetTranslation"),
    tScope = "previewGenDocModal";
  const canSubmit = usePermission(Targets.Sheet, Actions.Submit);
  const dispatch = useDispatch();
  return (
    <PermissionCheck target={Targets.Sheet} action={Actions.Download}>
      <ModalBuilder
        loadAction={() =>
          getSheetContract({ sheetId }, { watermark: true, isPreview: true })
        }
        submitAction={downloadSheetContract}
        submitParams={{ sheetId }}
        onSubmitted={onSubmitted}
        renderTrigger={
          renderTrigger ||
          ((onRender) => (
            <Button
              primary
              compact
              icon="print"
              disabled={disableTrigger}
              onClick={onRender}
              content={t("previewGenDocModal.trigger")}
            />
          ))
        }
        renderModal={(modalProps, data, state) => (
          <TranslatedModal.Edit
            {...modalProps}
            size="large"
            header={t(`${tScope}.header`)}
            submitLabel={t(`${tScope}.positiveLabel`)}
            cancelLabel={t(`${tScope}.negativeLabel`)}
            submitDisabled={
              state.loading || state.error || disableSubmit || !canSubmit
            }
            className="preview-document-modal"
          >
            {!state.loading && !state.error && (
              <InlinePdf src={data} initialScale={1.5} />
            )}

            <div className="action-buttons">
              <Button
                primary
                onClick={() => dispatch(downloadPrintPreview({ sheetId }))}
              >
                <Icon name="print" />
                Print Preview
              </Button>
              <div className="button-on-right">
                <label className="back" onClick={modalProps.onCancel}>
                  Back
                </label>
                {process.env.TRADE_AS_IPS && !downloadOnly ? (
                  <PermissionCheck
                    target={Targets.Sheet}
                    action={Actions.SubmitForCustomerApproval}
                  >
                    <SendForApproval
                      sheetData={sheet}
                      onSubmitted={onSubmitted}
                      sheetActionRequired={SheetAction.CanStartDocusignProcess}
                      generateDocument={true}
                      scheduleReference={sheet.scheduleReference}
                      agreementReference={sheet.agreementReference}
                      boilerPlateTandCsId={sheet.boilerPlateTandCsId}
                    />
                  </PermissionCheck>
                ) : (
                  <Button
                    className="generate-btn"
                    primary
                    onClick={modalProps.onSubmit}
                  >
                    <Icon name="check" />
                    Generate Document
                  </Button>
                )}
              </div>
            </div>
          </TranslatedModal.Edit>
        )}
      />
    </PermissionCheck>
  );
};

const mapStateToProps = (state) => {
  const sheetDataState = getSheetDataState(state);
  return {
    sheet: sheetDataState,
  };
};

export default compose(connect(mapStateToProps))(PreviewGenerateDocument);
