import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { Wizard, getResponsiveScreen } from "@redriver/cinnamon";
import {
  UserTypeCheck,
  PermissionCheck,
  withPermissions,
  withCurrentUser,
} from "features/../../../shared/components/auth";
import ScheduleSetupStep from "./ScheduleSetupStep";
import IPSyndicateRatesStep from "./IPSyndicateRatesStep";
import CapitalStep from "./CapitalStep";
import ServicesStep from "./ServicesStep";
import ScheduleStep from "./ScheduleStep";
import PaperStep from "./PaperStep";
import WizardNavigation from "./WizardNavigation";
import Summary from "./Summary";
import SheetHeader from "./SheetHeader";
import {
  getSheetState,
  getSheetRatesState,
  getSheetDataState,
} from "../selectors";

import { ToggleSwitch } from "components/buttons";
import { Actions, Targets } from "constants/permissions";
import {
  loadSheetDetails,
  clearSheetDetails,
  saveDetails,
  lockSheet,
  unlockSheet,
  loadSheetServices,
  loadUnitLookupData,
  setSheetViewType,
  calculateForm,
} from "./actions";
import {
  paymentMethods,
  SheetViewType,
  PaymentMethod,
  SheetSteps,
  UserType,
  AgreementStabilityOptions,
  SheetType,
} from "features/../../../shared/constants/enums";

import classNames from "classnames";
import { FloatingScrollBox } from "components/menus";
import LockMessage from "./LockMessage";
import SheetStatusMessage from "./SheetStatusMessage";
import CustomerStep from "./CustomerStep";
import { ProductScheduleList } from "../ManageProductSchedule";
import FinanceTypeStatusMessage from "./Messages/FinanceTypeStatusMessage";
import { useTranslation } from "react-i18next";
import ScheduleStabilityStep from "./ScheduleStabilityStep";
import { AgreementStabilityOptionsString } from "features/../../../shared/constants/enums/AgreementStabilityOptions";

const ManageSheet = ({
  sheetId,
  loadFailed,
  isRewrite,
  ratesContent,
  capitalContent,
  servicesContent,
  scheduleContent,
  paperContent,
  calculating,
  hasPermission,
  screenSize,
  ipsRatesCompleted,
  ratesCompleted,
  capitalCompleted,
  servicesCompleted,
  scheduleCompleted,
  scheduleStabilityCompleted,
  paperCompleted,
  paperHasContent,
  clearSheetDetails,
  loadSheetDetails,
  loadUnitLookupData,
  calculateForm,
  unlockSheet,
  loadSheetServices,
  sheetViewType,
  setSheetViewType,
  isReadOnly,
  lockedByName,
  state,
  stateText,
  currentUser,
  contractType,
  impersonatedUser,
  restrictions,
  funderTypeSettings,
  funderFinanceTypeChanged,
  dealerFinanceTypeChanged,
  coFundedFinanceTypeChanged,
  calculated,
  sheetData,
}) => {
  const { t } = useTranslation("sheetSummary");
  const [pageView, setPageView] = useState(false),
    [customerView, setCustomerView] = useState(
      !process.env.DISABLE_DEFAULT_CUSTOMER_VIEW,
    );

  const [impersonatedUserFlicker, setImpersonatedUserFlicker] = useState(true);
  const [ipSyndicateRatesCollapsed, setIpSyndicateRatesCollapsed] =
    useState(true);
  const [ratesCollapsed, setRatesCollapsed] = useState(true);
  const [capitalCalcCollapsed, setCapitalCalcCollapsed] = useState(false);
  const [servicesCollapsed, setServicesCollapsed] = useState(true);
  const [scheduleCollapsed, setScheduleCollapsed] = useState(true);
  const [scheduleStabilityCollapsed, setScheduleStabilityCollapsed] =
    useState(true);
  const [paperCollapsed, setPaperCollapsed] = useState(true);
  const [hasLockAccess, setHasLockAccess] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [adminUnlocked, setAdminUnlocked] = useState(true);
  const [productScheduleViewable, setProductScheduleViewable] = useState(false);

  const hasIpSyndicateViewPermission = useMemo(
    () => hasPermission(Targets.Sheet, Actions.ViewIPSyndicateRates),
    [],
  );
  const hasScheduleSetupViewPermission = useMemo(
    () => hasPermission(Targets.Sheet, Actions.ViewScheduleSetup),
    [],
  );
  const hasCapitalCalcViewPermission = useMemo(
    () => hasPermission(Targets.Sheet, Actions.ViewCapitalCalc),
    [],
  );
  const hasQuarterlyServiceViewPermission = useMemo(
    () => hasPermission(Targets.Sheet, Actions.ViewQuarterlyService),
    [],
  );
  const hasCutSheetPaperViewPermission = useMemo(
    () => hasPermission(Targets.Sheet, Actions.ViewCutSheetPaper),
    [],
  );
  const hasStabilityScheduleViewPermission = useMemo(
    () => hasPermission(Targets.Sheet, Actions.ViewScheduleStability),
    [],
  );
  const hasSheetAdminEditPermission = useMemo(
    () => hasPermission(Targets.SheetAdmin, Actions.Edit),
    [],
  );

  useEffect(
    function () {
      (async () => {
        clearSheetDetails();
        await loadSheetServices(sheetId, true);
        await loadSheetDetails(sheetId);
        // TODO: Fix the error with SalesPeople not being able to editing a non-open sheet
        /*}.then(data => {
        if (data && !data.lockedById) {
          {
            lockSheet(sheetId).then(() => {
              setHasLockAccess(true);
              setIsLoading(false);
            });
          }
        } else if (data && data.lockedById) {
          const lockedId = data.lockedById.toLowerCase().replace(/-/g, "");
          if (lockedId == currentUser.id.toLowerCase().replace(/-/g, "")) {
            setHasLockAccess(true);
          } else {
            setHasLockAccess(false);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })*/
        await loadUnitLookupData(sheetId);
        setSheetViewType(
          !process.env.DISABLE_DEFAULT_CUSTOMER_VIEW
            ? SheetViewType.Customer
            : currentUser.userType === UserType.HQ
            ? SheetViewType.IpsAdmin
            : hasSheetAdminEditPermission
            ? SheetViewType.SupplierAdmin
            : SheetViewType.AccountManager,
        );

        return () => {
          setIsLoading(true);
          unlockSheet(sheetId);
        };
      })();
    },
    [
      clearSheetDetails,
      currentUser.userType,
      loadSheetDetails,
      loadSheetServices,
      loadUnitLookupData,
      setSheetViewType,
      sheetId,
      unlockSheet,
      hasSheetAdminEditPermission,
    ],
  );

  useEffect(() => {
    function recalculate(e) {
      const path = e.path || e.composedPath();
      const fixedInputRegex = new RegExp("fix-input-container");
      const fixedStringRegex = new RegExp("fix-string");
      const newQuarterlyServiceRegex = new RegExp(
        "new-quarterly-service-dropdown",
      );

      if (
        Array.isArray(path) &&
        ((path.some((p) => fixedInputRegex.test(p.className)) &&
          !path.some((p) => fixedStringRegex.test(p.className))) ||
          path.some((p) => newQuarterlyServiceRegex.test(p.className)))
      ) {
        calculateForm(true);
      }
    }

    window.addEventListener("focusout", recalculate);
    return () => window.removeEventListener("focusout", recalculate);
  }, [calculateForm]);

  const canEditAdminFields =
    hasPermission(Targets.SheetAdmin, Actions.Edit) &&
    impersonatedUser.canEditAdminFields;

  const [stepKey, setStepKey] = useState(
    currentUser.userType === UserType.HQ && hasIpSyndicateViewPermission
      ? SheetSteps.IPSyndicateRates
      : canEditAdminFields && hasScheduleSetupViewPermission
      ? SheetSteps.Rates
      : hasCapitalCalcViewPermission
      ? SheetSteps.Capital
      : hasQuarterlyServiceViewPermission
      ? SheetSteps.Services
      : hasCutSheetPaperViewPermission
      ? SheetSteps.PaperInclusive
      : SheetSteps.Schedule,
  );

  //if sheet step isn't available we must skip to next available sheet step
  useEffect(() => {
    if (
      stepKey === SheetSteps.IPSyndicateRates &&
      sheetViewType === SheetViewType.SupplierAdmin
    ) {
      setStepKey(SheetSteps.Rates);
    }

    if (
      (stepKey === SheetSteps.IPSyndicateRates ||
        stepKey === SheetSteps.Rates) &&
      sheetViewType === SheetViewType.AccountManager
    ) {
      setStepKey(SheetSteps.Capital);
    }
  }, [sheetViewType, stepKey]);

  const displayPaperInclusive = useMemo(
    () =>
      restrictions &&
      (sheetViewType === SheetViewType.IpsAdmin ||
        (restrictions.paperInclusiveAvailableToDealer &&
          sheetViewType === SheetViewType.SupplierAdmin) ||
        (restrictions.paperInclusiveAvailableToDealer &&
          restrictions.paperInclusiveAvailableToAll &&
          sheetViewType === SheetViewType.AccountManager)),
  );

  const isAdminView = useMemo(
    () =>
      sheetViewType === SheetViewType.IpsAdmin ||
      sheetViewType === SheetViewType.SupplierAdmin,
    [sheetViewType],
  );

  const setCollapsedAll = (collapsed) => {
    setIpSyndicateRatesCollapsed(collapsed);
    setRatesCollapsed(collapsed);
    setCapitalCalcCollapsed(collapsed);
    setServicesCollapsed(collapsed);
    setScheduleCollapsed(collapsed);
    setScheduleStabilityCollapsed(collapsed);
    setPaperCollapsed(collapsed);
  };

  const customerToggle = () => {
    setCustomerView(!customerView);
  };

  // Refs for Scroll-to-section in view-all mode
  const ipSyndicateRatesRef = useRef(null);
  const ratesRef = useRef(null);
  const capitalRef = useRef(null);
  const servicesRef = useRef(null);
  const scheduleRef = useRef(null);
  const scheduleStabilityRef = useRef(null);
  const paperRef = useRef(null);

  const scheduleStabilityData = useSelector(
    (state) =>
      state.sheets?.rawCalculation?.scheduleStability?.adjustmentSelection,
  );
  const agreementStabilityOption =
    sheetData?.scheduleStability.agreementStabilityOption;

  const scheduleStabilityContent = useMemo(() => {
    let value = 0;

    if (
      agreementStabilityOption != null &&
      scheduleStabilityData != null &&
      agreementStabilityOption != AgreementStabilityOptions.Option1
    ) {
      const scheduleStability =
        scheduleStabilityData[agreementStabilityOption.toLowerCase()];
      value = scheduleStability?.additionalToSchedule.value;
    }

    return {
      value: value,
      option: AgreementStabilityOptionsString[agreementStabilityOption],
    };
  }, [agreementStabilityOption, scheduleStabilityData]);

  const stepData = useMemo(
    () =>
      [
        {
          key: SheetSteps.IPSyndicateRates,
          title: t("IP_Syndicate_Rates_&_Settings"),
          contentType: "none",
          isComplete: ipsRatesCompleted,
          restrictToInternalUsers: true,
          ref: ipSyndicateRatesRef,
          hasViewPermission: hasIpSyndicateViewPermission,
        },
        {
          key: SheetSteps.Rates,
          title: t("Schedule_Set_Up"),
          content: ratesContent,
          contentType: "none",
          isComplete: ratesCompleted,
          permissionTarget: Targets.SheetAdmin,
          sheetViewTypes: [SheetViewType.IpsAdmin, SheetViewType.SupplierAdmin],
          ref: ratesRef,
          hasViewPermission: hasScheduleSetupViewPermission,
        },
        {
          key: SheetSteps.Capital,
          title: t("Capital_Calc"),
          content: capitalContent,
          contentType: "splitTotals",
          isComplete: capitalCompleted,
          ref: capitalRef,
          hasViewPermission: hasCapitalCalcViewPermission,
        },
        {
          key: SheetSteps.Services,
          title: t("Quarterly_Services"),
          content: servicesContent,
          contentType: "splitTotals",
          isComplete: servicesCompleted,
          ref: servicesRef,
          hasViewPermission: hasQuarterlyServiceViewPermission,
        },
        {
          key: SheetSteps.PaperInclusive,
          title: t("Cut_Sheet_Paper_Inclusive"),
          content: paperContent,
          hasContent: paperHasContent,
          contentType: "splitTotals",
          isComplete: paperCompleted,
          ref: paperRef,
          hasViewPermission: hasCutSheetPaperViewPermission,
        },
        {
          key: SheetSteps.Schedule,
          title: t("System_Schedule"),
          content: scheduleContent,
          contentType: "num",
          isComplete: scheduleCompleted,
          ref: scheduleRef,
          hasViewPermission: true, // as permission is for schedule or lfp items - panel is always visible
        },
        {
          key: SheetSteps.ScheduleStability,
          title: t("Schedule_Stability"),
          content: scheduleStabilityContent,
          contentType: "none",
          isComplete: scheduleStabilityCompleted,
          permissionTarget: Targets.SheetAdmin,
          sheetViewTypes: [SheetViewType.IpsAdmin, SheetViewType.SupplierAdmin],
          ref: scheduleStabilityRef,
          hasViewPermission: hasStabilityScheduleViewPermission,
        },
        {
          key: SheetSteps.All,
          title: t("All"),
          content: "",
          hasContent: false,
          contentType: null,
          isComplete: null,
          hasViewPermission: true,
        },
      ]
        .filter(
          (p) =>
            p.hasViewPermission &&
            (!p.permissionTarget ||
              hasPermission(p.permissionTarget, Actions.Edit)),
        )
        .filter(
          (x) =>
            x.key !== (displayPaperInclusive ? "" : SheetSteps.PaperInclusive),
        )
        .filter(
          (x) => x.key !== (isRewrite ? "" : SheetSteps.ScheduleStability),
        ),
    [
      ipsRatesCompleted,
      ipSyndicateRatesRef,
      hasIpSyndicateViewPermission,
      ratesContent,
      ratesCompleted,
      ratesRef,
      hasScheduleSetupViewPermission,
      capitalContent,
      capitalCompleted,
      capitalRef,
      hasCapitalCalcViewPermission,
      servicesContent,
      servicesCompleted,
      servicesRef,
      hasQuarterlyServiceViewPermission,
      paperContent,
      paperCompleted,
      paperRef,
      hasCutSheetPaperViewPermission,
      displayPaperInclusive,
      scheduleContent,
      scheduleCompleted,
      scheduleRef,
      scheduleStabilityContent,
      scheduleStabilityCompleted,
      scheduleStabilityRef,
      hasStabilityScheduleViewPermission,
      isRewrite,
    ],
  );

  const onStepChange = (newStepIndex) => {
    const step = stepData[newStepIndex];
    if (step) {
      setStepKey(step.key);
      if (!pageView && step.ref.current) {
        step.ref.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  if (loadFailed) {
    return (
      <h3 className="load-sheet-error">
        Could not load this Sheet. Please contact your administrator.
      </h3>
    );
  }

  const renderCustomerStep = (props) => (
    <CustomerStep
      {...props}
      sheetId={sheetId}
      calculating={calculating}
      displaySave={true}
      customerToggle={customerToggle}
      setImpersonatedUserFlicker={setImpersonatedUserFlicker}
      setProductScheduleViewable={() => setProductScheduleViewable(true)}
    />
  );

  return (
    <Wizard
      steps={stepData}
      step={!pageView ? SheetSteps.All : stepKey}
      onStepChange={onStepChange}
    >
      <div className="sheet-left-panel">
        <FloatingScrollBox>
          {!customerView && (
            <div className="section-1">
              <div className="top">
                <div className="page-view-container">
                  <ToggleSwitch
                    trueText={t("Page_View")}
                    trueClassName="page-view"
                    falseText={t("All_View")}
                    falseClassName="all-view"
                    value={pageView}
                    setValue={setPageView}
                  />
                  {!pageView && (
                    <div className="expand-collapse-all">
                      <label onClick={() => setCollapsedAll(false)}>
                        {t("Expand_all")}
                      </label>
                      <label>&nbsp;|&nbsp;</label>
                      <label onClick={() => setCollapsedAll(true)}>
                        {t("Collapse_all")}
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="sheet-wizard">
                <Wizard.Nav
                  render={(wizardContext) => (
                    <WizardNavigation
                      {...wizardContext}
                      pageView={pageView}
                      sheetViewType={sheetViewType}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <Summary
            ratesContent={ratesContent}
            customerView={customerView}
            sheetId={sheetId}
          />
        </FloatingScrollBox>
        {productScheduleViewable && (
          <div className="product-schedule-restrict">
            <div className="background" />
            <div className="foreground">
              <div>
                <ProductScheduleList
                  sheetId={sheetId}
                  setProductScheduleNotViewable={() =>
                    setProductScheduleViewable(false)
                  }
                  sheetViewType={sheetViewType}
                  disabled={isReadOnly}
                />{" "}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames(
          "sheet-right-panel",
          `screen-size-` + screenSize,
          !pageView && "view-all-steps",
        )}
      >
        {!hasLockAccess && !isLoading && (
          <LockMessage
            lockedByName={lockedByName}
            adminUnlocked={adminUnlocked}
            onForceUnlock={() => {
              unlockSheet(sheetId).then(() => {
                setAdminUnlocked(true);
                setHasLockAccess(true);
              });
            }}
          />
        )}
        <SheetStatusMessage
          state={state}
          stateText={stateText}
          sheetViewType={sheetViewType}
        />

        {/* If the default rate type has changed since the sheet was last loaded we must alert the user */}
        {funderFinanceTypeChanged && (
          <FinanceTypeStatusMessage financeType="Funder" />
        )}

        {dealerFinanceTypeChanged && (
          <FinanceTypeStatusMessage financeType="Supplier" />
        )}

        {coFundedFinanceTypeChanged && (
          <FinanceTypeStatusMessage financeType="Co-Funded" />
        )}

        <SheetHeader
          sheetId={sheetId}
          customerToggle={customerToggle}
          customerView={customerView}
          impersonatedUserFlicker={impersonatedUserFlicker}
          setImpersonatedUserFlicker={setImpersonatedUserFlicker}
        />

        <UserTypeCheck restrictTo={UserType.HQ}>
          {sheetViewType === SheetViewType.IpsAdmin && (
            <Wizard.Step
              step={SheetSteps.IPSyndicateRates}
              render={(props) => (
                <React.Fragment>
                  {customerView ? (
                    renderCustomerStep(props)
                  ) : (
                    <IPSyndicateRatesStep
                      {...props}
                      sheetId={sheetId}
                      screenSize={screenSize}
                      displaySave={true} // TODO hasLockAccess && isStatusEditableForUser}
                      displayPrevious={false}
                      calculating={calculating}
                      customerToggle={customerToggle}
                      displayCustomerToggle={true}
                      funderTypeSettings={funderTypeSettings}
                    />
                  )}
                </React.Fragment>
              )}
            />
          )}
        </UserTypeCheck>

        <PermissionCheck target={Targets.SheetAdmin} action={Actions.Edit}>
          {(sheetViewType === SheetViewType.IpsAdmin ||
            sheetViewType === SheetViewType.SupplierAdmin) &&
            impersonatedUser.canEditAdminFields && (
              <Wizard.Step
                step={SheetSteps.Rates}
                render={(props) => (
                  <React.Fragment>
                    {customerView ? (
                      renderCustomerStep(props)
                    ) : (
                      <ScheduleSetupStep
                        {...props}
                        sheetId={sheetId}
                        screenSize={screenSize}
                        displaySave={true} // TODO hasLockAccess && isStatusEditableForUser}
                        displayPrevious={
                          sheetViewType !== SheetViewType.SupplierAdmin
                        }
                        calculating={calculating}
                        customerToggle={customerToggle}
                        displayCustomerToggle={true}
                      />
                    )}
                  </React.Fragment>
                )}
              />
            )}
        </PermissionCheck>
        <Wizard.Step
          step={SheetSteps.Capital}
          render={(props) => (
            <React.Fragment>
              {customerView ? (
                renderCustomerStep(props)
              ) : (
                <CapitalStep
                  {...props}
                  sheetId={sheetId}
                  screenSize={screenSize}
                  displaySave={true} // TODO hasLockAccess && isStatusEditableForUser}
                  displayPrevious={
                    sheetViewType !== SheetViewType.AccountManager
                  }
                  calculating={calculating}
                  customerToggle={customerToggle}
                  displayCustomerToggle={true}
                />
              )}
            </React.Fragment>
          )}
        />
        <Wizard.Step
          step={SheetSteps.Services}
          render={(props) => (
            <React.Fragment>
              {customerView ? (
                renderCustomerStep(props)
              ) : (
                <ServicesStep
                  {...props}
                  sheetId={sheetId}
                  displaySave={true} // TODO hasLockAccess && isStatusEditableForUser}
                  calculating={calculating}
                  customerToggle={customerToggle}
                  displayCustomerToggle={true}
                />
              )}
            </React.Fragment>
          )}
        />

        {displayPaperInclusive && (
          <Wizard.Step
            step={SheetSteps.PaperInclusive}
            render={(props) => (
              <React.Fragment>
                {customerView ? (
                  renderCustomerStep(props)
                ) : (
                  <PaperStep
                    {...props}
                    sheetId={sheetId}
                    calculating={calculating}
                    displaySave={true} // TODO hasLockAccess && isStatusEditableForUser}
                    displayNext={true}
                    customerToggle={customerToggle}
                    displayCustomerToggle={true}
                  />
                )}
              </React.Fragment>
            )}
          />
        )}

        <Wizard.Step
          step={SheetSteps.Schedule}
          render={(props) => (
            <React.Fragment>
              {customerView ? (
                renderCustomerStep(props)
              ) : (
                <ScheduleStep
                  {...props}
                  sheetId={sheetId}
                  numberOfSchedules={scheduleContent}
                  calculating={calculating}
                  displaySave={true} // TODO hasLockAccess && isStatusEditableForUser}
                  displayNext={isAdminView}
                  contractType={contractType}
                  customerToggle={customerToggle}
                  displayCustomerToggle={true}
                  setProductScheduleViewable={() =>
                    setProductScheduleViewable(true)
                  }
                  userType={currentUser.userType}
                />
              )}
            </React.Fragment>
          )}
        />

        <Wizard.Step
          step={SheetSteps.ScheduleStability}
          render={(props) => (
            <React.Fragment>
              {customerView ? (
                renderCustomerStep(props)
              ) : isRewrite ? (
                <ScheduleStabilityStep
                  {...props}
                  sheetId={sheetId}
                  reactRef={scheduleStabilityRef}
                  calculating={calculating}
                  displayNext={false}
                  displaySave={isAdminView}
                  customerToggle={customerToggle}
                  displayCustomerToggle={true}
                  currentUser={currentUser}
                  contractType={contractType}
                  userType={currentUser.userType}
                />
              ) : null}
            </React.Fragment>
          )}
        />
        <Wizard.Step
          step={SheetSteps.All}
          render={(props) => (
            <React.Fragment>
              {customerView ? (
                renderCustomerStep(props)
              ) : (
                <React.Fragment>
                  <UserTypeCheck restrictTo={UserType.HQ}>
                    {sheetViewType === SheetViewType.IpsAdmin && (
                      <IPSyndicateRatesStep
                        {...props}
                        sheetId={sheetId}
                        reactRef={ipSyndicateRatesRef}
                        screenSize={screenSize}
                        displayPrevious={false}
                        displayNext={false}
                        displaySave={false}
                        collapsed={ipSyndicateRatesCollapsed}
                        toggleVisibility={() =>
                          setIpSyndicateRatesCollapsed(
                            !ipSyndicateRatesCollapsed,
                          )
                        }
                        calculating={calculating}
                        funderTypeSettings={funderTypeSettings}
                      />
                    )}
                  </UserTypeCheck>
                  {canEditAdminFields &&
                    (sheetViewType === SheetViewType.IpsAdmin ||
                      sheetViewType === SheetViewType.SupplierAdmin) && (
                      <ScheduleSetupStep
                        {...props}
                        reactRef={ratesRef}
                        screenSize={screenSize}
                        displayPrevious={false}
                        displayNext={false}
                        displaySave={false}
                        collapsed={ratesCollapsed}
                        sheetId={sheetId}
                        toggleVisibility={() =>
                          setRatesCollapsed(!ratesCollapsed)
                        }
                        calculating={calculating}
                      />
                    )}
                  <CapitalStep
                    {...props}
                    reactRef={capitalRef}
                    screenSize={screenSize}
                    displayPrevious={false}
                    displayNext={false}
                    displaySave={false}
                    collapsed={capitalCalcCollapsed}
                    sheetId={sheetId}
                    toggleVisibility={() =>
                      setCapitalCalcCollapsed(!capitalCalcCollapsed)
                    }
                    calculating={calculating}
                  />
                  <ServicesStep
                    {...props}
                    reactRef={servicesRef}
                    sheetId={sheetId}
                    displayPrevious={false}
                    displayNext={false}
                    displaySave={false}
                    collapsed={servicesCollapsed}
                    toggleVisibility={() =>
                      setServicesCollapsed(!servicesCollapsed)
                    }
                    calculating={calculating}
                  />

                  {displayPaperInclusive && (
                    <PaperStep
                      {...props}
                      sheetId={sheetId}
                      reactRef={paperRef}
                      calculating={calculating}
                      displayPrevious={false}
                      displayNext={false}
                      displaySave={false} // TODO hasLockAccess && isStatusEditableForUser}
                      collapsed={paperCollapsed}
                      toggleVisibility={() =>
                        setPaperCollapsed(!paperCollapsed)
                      }
                    />
                  )}
                  <ScheduleStep
                    {...props}
                    sheetId={sheetId}
                    reactRef={scheduleRef}
                    numberOfSchedules={scheduleContent}
                    calculating={calculating}
                    displayPrevious={false}
                    displayNext={false}
                    onNextStep={setPageView(false)}
                    displaySave={
                      !isAdminView ||
                      (isRewrite && !hasStabilityScheduleViewPermission) ||
                      !calculated ||
                      !isRewrite
                    }
                    collapsed={scheduleCollapsed}
                    customerToggle={customerToggle}
                    displayCustomerToggle={true}
                    toggleVisibility={() =>
                      setScheduleCollapsed(!scheduleCollapsed)
                    }
                    contractType={contractType}
                    setProductScheduleViewable={() =>
                      setProductScheduleViewable(true)
                    }
                    userType={currentUser.userType}
                  />

                  {isAdminView && isRewrite && (
                    <ScheduleStabilityStep
                      {...props}
                      sheetId={sheetId}
                      reactRef={scheduleStabilityRef}
                      calculating={calculating}
                      displayPrevious={false}
                      displayNext={false}
                      onNextStep={setPageView(false)}
                      displaySave={true}
                      collapsed={scheduleStabilityCollapsed}
                      customerToggle={customerToggle}
                      displayCustomerToggle={true}
                      toggleVisibility={() =>
                        setScheduleStabilityCollapsed(
                          !scheduleStabilityCollapsed,
                        )
                      }
                      currentUser={currentUser}
                      contractType={contractType}
                      userType={currentUser.userType}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        />
      </div>
    </Wizard>
  );
};

const actions = {
  loadSheetDetails,
  clearSheetDetails,
  saveDetails,
  lockSheet,
  unlockSheet,
  loadSheetServices,
  loadUnitLookupData,
  setSheetViewType,
  calculateForm,
};

const mapStateToProps = (state) => {
  const {
    loadFailed,
    capitalContent,
    servicesContent,
    scheduleContent,
    paperContent,
    largeFormatContent,
    calculating,
    ipsRatesCompleted,
    ratesCompleted,
    capitalCompleted,
    servicesCompleted,
    scheduleCompleted,
    scheduleStabilityCompleted,
    paperCompleted,
    paperHasContent,
    largeFormatCompleted,
    sheetViewType,
    rawCalculation,
  } = getSheetState(state);
  const { paymentMethod } = getSheetRatesState(state);
  const { screenSize } = getResponsiveScreen(state);
  const sheetData = getSheetDataState(state);
  const { lockedById, lockedByName, stateText, contractType, isReadOnly } =
    sheetData;
  const method = paymentMethods.filter((m) => m.value === paymentMethod)[0];

  const { sheetType } = getSheetRatesState(state);
  return {
    loadFailed,
    isRewrite:
      sheetType == SheetType.Rewrite ||
      sheetType == SheetType.RewriteNewMasterDocument,
    ratesContent: method ? method.text : PaymentMethod.QuarterlyDirectDebit,
    capitalContent,
    servicesContent,
    scheduleContent,
    paperContent,
    largeFormatContent,
    calculating,
    screenSize,
    ipsRatesCompleted,
    ratesCompleted,
    capitalCompleted,
    servicesCompleted,
    scheduleCompleted,
    scheduleStabilityCompleted,
    paperCompleted,
    paperHasContent,
    largeFormatCompleted,
    lockedById: lockedById,
    lockedByName,
    state: sheetData.state,
    stateText,
    contractType,
    sheetViewType,
    isReadOnly,
    sheetData,
    impersonatedUser: sheetData.impersonatedUser,
    restrictions: sheetData.restrictions,
    funderTypeSettings: sheetData.defaults.funderTypeSettings,
    funderFinanceTypeChanged: sheetData.rates.funderFmv.financeTypeChanged,
    dealerFinanceTypeChanged: sheetData.rates.dealerFmv.financeTypeChanged,
    coFundedFinanceTypeChanged: sheetData.rates.cofundedFmv.financeTypeChanged,
    calculated: rawCalculation != null,
  };
};

export default compose(
  connect(mapStateToProps, actions),
  withPermissions,
  withCurrentUser,
)(ManageSheet);
