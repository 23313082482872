// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon";
import { RateTypeLookupView } from "mortar/Principal/Endpoints/Lookups";
import { ContractType, PaperSize, UserArea } from "mortar/Principal/Enums";
import { ClientAuthorisedSignatoryLookupView } from "mortar/Principal/Logic/Clients/ClientAuthorisedSignatories/Views";
import {
    ClientAddressView,
    ClientBasicView,
} from "mortar/Principal/Logic/Clients/Views";
import { DealerLookupView } from "mortar/Principal/Logic/Dealers/Views";
import { LookupView } from "mortar/Principal/Logic/Helpers";
import { ApplicableItemLookupViewBase } from "mortar/Principal/Logic/Lookups";
import {
    ImpersonateUsersLookupView,
    LargeFormatMeteredServiceLookupView,
    MeteredServiceLookupView,
} from "mortar/Principal/Logic/Lookups/Views";
import { PaperBasicView } from "mortar/Principal/Logic/Settings/Papers/Views";
import {
    InkInclusiveLookupView,
    InkUsageLookupView,
    LFPaperInclusiveLookupView,
    PrinterConfigLookupView,
    PrinterWidthsLookupView,
    RollMediaNameLookupView,
    TargetMarketLookupView,
} from "mortar/Principal/Logic/Settings/Printers/Views";
import { SPVBasicView } from "mortar/Principal/Logic/SPVs/Views";
import { UserBasicView } from "mortar/Principal/Logic/Users/Views";
import { KeyValuePair } from "mortar/System/Collections/Generic";
import { makeAction, makeFormData } from "../lib";

export interface AccountTypeLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<
            KeyValuePair<string, string>[],
            "lookups/accountTypeLookup"
        >
    >;
}

export const accountTypeLookup = makeAction((
    { options }: AccountTypeLookupActionRequest = {},
) => apiGet<KeyValuePair<string, string>[], "lookups/accountTypeLookup">(
    "lookups/accountTypeLookup",
    `lookups/account-type`,
    undefined,
    options,
), "lookups/accountTypeLookup");

export interface BoilerPlateTandCsLookupQueryParams {
    search: string;
    dealerId: string;
}

export interface BoilerPlateTandCsLookupActionRequest {
    queryParams: BoilerPlateTandCsLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/boilerPlateTandCsLookup">
    >;
}

export const boilerPlateTandCsLookup = makeAction((
    { queryParams, options }: BoilerPlateTandCsLookupActionRequest,
) => apiGet<LookupView[], "lookups/boilerPlateTandCsLookup">(
    "lookups/boilerPlateTandCsLookup",
    `lookups/boiler-plate-terms`,
    queryParams,
    options,
), "lookups/boilerPlateTandCsLookup");

export interface BusinessTypesLookupActionRequest {
    options?: Partial<ApiRequestOptions<void, "lookups/businessTypesLookup">>;
}

export const businessTypesLookup = makeAction((
    { options }: BusinessTypesLookupActionRequest = {},
) => apiGet<void, "lookups/businessTypesLookup">(
    "lookups/businessTypesLookup",
    `lookups/business-types`,
    undefined,
    options,
), "lookups/businessTypesLookup");

export interface ClientsLookupQueryParams {
    dealerOfficeId: string;
    search: string;
}

export interface ClientsLookupActionRequest {
    queryParams: ClientsLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<ClientBasicView[], "lookups/clientsLookup">
    >;
}

export const clientsLookup = makeAction((
    { queryParams, options }: ClientsLookupActionRequest,
) => apiGet<ClientBasicView[], "lookups/clientsLookup">(
    "lookups/clientsLookup",
    `lookups/clients`,
    queryParams,
    options,
), "lookups/clientsLookup");

export interface ClientAddressLookupQueryParams {
    clientId: string;
}

export interface ClientAddressLookupActionRequest {
    queryParams: ClientAddressLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<ClientAddressView[], "lookups/clientAddressLookup">
    >;
}

export const clientAddressLookup = makeAction((
    { queryParams, options }: ClientAddressLookupActionRequest,
) => apiGet<ClientAddressView[], "lookups/clientAddressLookup">(
    "lookups/clientAddressLookup",
    `lookups/clients/addresses`,
    queryParams,
    options,
), "lookups/clientAddressLookup");

export interface ClientAuthSignsLookupQueryParams {
    sheetId: string;
    queryTerm: string;
    pageSize: number;
}

export interface ClientAuthSignsLookupActionRequest {
    queryParams: ClientAuthSignsLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            ClientAuthorisedSignatoryLookupView[],
            "lookups/clientAuthSignsLookup"
        >
    >;
}

export const clientAuthSignsLookup = makeAction((
    { queryParams, options }: ClientAuthSignsLookupActionRequest,
) => apiGet<
    ClientAuthorisedSignatoryLookupView[],
    "lookups/clientAuthSignsLookup"
>(
    "lookups/clientAuthSignsLookup",
    `lookups/clients/authorised-signatories`,
    queryParams,
    options,
), "lookups/clientAuthSignsLookup");

export interface ContractTypeLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<
            KeyValuePair<string, string>[],
            "lookups/contractTypeLookup"
        >
    >;
}

export const contractTypeLookup = makeAction((
    { options }: ContractTypeLookupActionRequest = {},
) => apiGet<KeyValuePair<string, string>[], "lookups/contractTypeLookup">(
    "lookups/contractTypeLookup",
    `lookups/contract-types`,
    undefined,
    options,
), "lookups/contractTypeLookup");

export interface DealerOfficesLookupQueryParams {
    search: string;
    dealerId: string;
}

export interface DealerOfficesLookupActionRequest {
    queryParams: DealerOfficesLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/dealerOfficesLookup">
    >;
}

export const dealerOfficesLookup = makeAction((
    { queryParams, options }: DealerOfficesLookupActionRequest,
) => apiGet<LookupView[], "lookups/dealerOfficesLookup">(
    "lookups/dealerOfficesLookup",
    `lookups/dealer-offices`,
    queryParams,
    options,
), "lookups/dealerOfficesLookup");

export interface DealerStatusActionRequest {
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/dealerStatus">>;
}

export const dealerStatus = makeAction((
    { options }: DealerStatusActionRequest = {},
) => apiGet<LookupView[], "lookups/dealerStatus">(
    "lookups/dealerStatus",
    `lookups/dealer-status`,
    undefined,
    options,
), "lookups/dealerStatus");

export interface DealersLookupQueryParams {
    search: string;
    showSPV: boolean;
    includeFunderSuppliersOnly: boolean;
    funderId: string;
}

export interface DealersLookupActionRequest {
    queryParams: DealersLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<DealerLookupView[], "lookups/dealersLookup">
    >;
}

export const dealersLookup = makeAction((
    { queryParams, options }: DealersLookupActionRequest,
) => apiGet<DealerLookupView[], "lookups/dealersLookup">(
    "lookups/dealersLookup",
    `lookups/dealers`,
    queryParams,
    options,
), "lookups/dealersLookup");

export interface FmvTypesLookupActionRequest {
    options?: Partial<ApiRequestOptions<void, "lookups/fmvTypesLookup">>;
}

export const fmvTypesLookup = makeAction((
    { options }: FmvTypesLookupActionRequest = {},
) => apiGet<void, "lookups/fmvTypesLookup">(
    "lookups/fmvTypesLookup",
    `lookups/fmv-types`,
    undefined,
    options,
), "lookups/fmvTypesLookup");

export interface FunderSuppliersLookupQueryParams {
    funderId: string;
    search: string;
}

export interface FunderSuppliersLookupActionRequest {
    queryParams: FunderSuppliersLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/funderSuppliersLookup">
    >;
}

export const funderSuppliersLookup = makeAction((
    { queryParams, options }: FunderSuppliersLookupActionRequest,
) => apiGet<LookupView[], "lookups/funderSuppliersLookup">(
    "lookups/funderSuppliersLookup",
    `lookups/funder-suppliers`,
    queryParams,
    options,
), "lookups/funderSuppliersLookup");

export interface ImpersonateUsersLookupQueryParams {
    sheetId: string;
}

export interface ImpersonateUsersLookupActionRequest {
    queryParams: ImpersonateUsersLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            ImpersonateUsersLookupView[],
            "lookups/impersonateUsersLookup"
        >
    >;
}

export const impersonateUsersLookup = makeAction((
    { queryParams, options }: ImpersonateUsersLookupActionRequest,
) => apiGet<ImpersonateUsersLookupView[], "lookups/impersonateUsersLookup">(
    "lookups/impersonateUsersLookup",
    `lookups/impersonate-users`,
    queryParams,
    options,
), "lookups/impersonateUsersLookup");

export interface LargeFormatMeteredServicesLookupQueryParams {
    sheetId: string;
    dealerOfficeId: string;
    contractType: ContractType;
}

export interface LargeFormatMeteredServicesLookupActionRequest {
    queryParams: LargeFormatMeteredServicesLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            LargeFormatMeteredServiceLookupView[],
            "lookups/largeFormatMeteredServicesLookup"
        >
    >;
}

export const largeFormatMeteredServicesLookup = makeAction((
    { queryParams, options }: LargeFormatMeteredServicesLookupActionRequest,
) => apiGet<
    LargeFormatMeteredServiceLookupView[],
    "lookups/largeFormatMeteredServicesLookup"
>(
    "lookups/largeFormatMeteredServicesLookup",
    `lookups/large-format-metered-services`,
    queryParams,
    options,
), "lookups/largeFormatMeteredServicesLookup");

export interface LargeFormatInkInclusiveLookupQueryParams {
    sheetId: string;
    printerId: string;
    search: string;
}

export interface LargeFormatInkInclusiveLookupActionRequest {
    queryParams: LargeFormatInkInclusiveLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            InkInclusiveLookupView[],
            "lookups/largeFormatInkInclusiveLookup"
        >
    >;
}

export const largeFormatInkInclusiveLookup = makeAction((
    { queryParams, options }: LargeFormatInkInclusiveLookupActionRequest,
) => apiGet<InkInclusiveLookupView[], "lookups/largeFormatInkInclusiveLookup">(
    "lookups/largeFormatInkInclusiveLookup",
    `lookups/large-format/ink-inclusive`,
    queryParams,
    options,
), "lookups/largeFormatInkInclusiveLookup");

export interface InkUsageLookupQueryParams {
    dealerOfficeId: string;
}

export interface InkUsageLookupActionRequest {
    queryParams: InkUsageLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<InkUsageLookupView[], "lookups/inkUsageLookup">
    >;
}

export const inkUsageLookup = makeAction((
    { queryParams, options }: InkUsageLookupActionRequest,
) => apiGet<InkUsageLookupView[], "lookups/inkUsageLookup">(
    "lookups/inkUsageLookup",
    `lookups/large-format/ink-usage`,
    queryParams,
    options,
), "lookups/inkUsageLookup");

export interface LargeFormatPaperInclusiveLookupQueryParams {
    sheetId: string;
    paperWidth: number;
    search: string;
    dealerOfficeId: string;
}

export interface LargeFormatPaperInclusiveLookupActionRequest {
    queryParams: LargeFormatPaperInclusiveLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            LFPaperInclusiveLookupView[],
            "lookups/largeFormatPaperInclusiveLookup"
        >
    >;
}

export const largeFormatPaperInclusiveLookup = makeAction((
    { queryParams, options }: LargeFormatPaperInclusiveLookupActionRequest,
) => apiGet<
    LFPaperInclusiveLookupView[],
    "lookups/largeFormatPaperInclusiveLookup"
>(
    "lookups/largeFormatPaperInclusiveLookup",
    `lookups/large-format/paper-inclusive`,
    queryParams,
    options,
), "lookups/largeFormatPaperInclusiveLookup");

export interface LargeFormatPrinterConfigLookupQueryParams {
    sheetId: string;
    search: string;
}

export interface LargeFormatPrinterConfigLookupActionRequest {
    queryParams: LargeFormatPrinterConfigLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            PrinterConfigLookupView[],
            "lookups/largeFormatPrinterConfigLookup"
        >
    >;
}

export const largeFormatPrinterConfigLookup = makeAction((
    { queryParams, options }: LargeFormatPrinterConfigLookupActionRequest,
) => apiGet<
    PrinterConfigLookupView[],
    "lookups/largeFormatPrinterConfigLookup"
>(
    "lookups/largeFormatPrinterConfigLookup",
    `lookups/large-format/printer-config`,
    queryParams,
    options,
), "lookups/largeFormatPrinterConfigLookup");

export interface LargeFormatPrinterWidthsLookupQueryParams {
    dealerOfficeId: string;
}

export interface LargeFormatPrinterWidthsLookupActionRequest {
    queryParams: LargeFormatPrinterWidthsLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            PrinterWidthsLookupView[],
            "lookups/largeFormatPrinterWidthsLookup"
        >
    >;
}

export const largeFormatPrinterWidthsLookup = makeAction((
    { queryParams, options }: LargeFormatPrinterWidthsLookupActionRequest,
) => apiGet<
    PrinterWidthsLookupView[],
    "lookups/largeFormatPrinterWidthsLookup"
>(
    "lookups/largeFormatPrinterWidthsLookup",
    `lookups/large-format/printer-widths`,
    queryParams,
    options,
), "lookups/largeFormatPrinterWidthsLookup");

export interface RollMediaNameLookupQueryParams {
    sheetId: string;
    paperWidthId: string;
    search: string;
}

export interface RollMediaNameLookupActionRequest {
    queryParams: RollMediaNameLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            RollMediaNameLookupView[],
            "lookups/rollMediaNameLookup"
        >
    >;
}

export const rollMediaNameLookup = makeAction((
    { queryParams, options }: RollMediaNameLookupActionRequest,
) => apiGet<RollMediaNameLookupView[], "lookups/rollMediaNameLookup">(
    "lookups/rollMediaNameLookup",
    `lookups/large-format/roll-media-name`,
    queryParams,
    options,
), "lookups/rollMediaNameLookup");

export interface MeteredServicesLookupQueryParams {
    sheetId: string;
    dealerOfficeId: string;
    contractType: ContractType;
    isLargeFormat: boolean;
}

export interface MeteredServicesLookupActionRequest {
    queryParams: MeteredServicesLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            MeteredServiceLookupView[],
            "lookups/meteredServicesLookup"
        >
    >;
}

export const meteredServicesLookup = makeAction((
    { queryParams, options }: MeteredServicesLookupActionRequest,
) => apiGet<MeteredServiceLookupView[], "lookups/meteredServicesLookup">(
    "lookups/meteredServicesLookup",
    `lookups/metered-services`,
    queryParams,
    options,
), "lookups/meteredServicesLookup");

export interface PaperLookupQueryParams {
    sheetId: string;
    description: string;
    paperSize: PaperSize;
}

export interface PaperLookupActionRequest {
    queryParams: PaperLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<PaperBasicView[], "lookups/paperLookup">
    >;
}

export const paperLookup = makeAction((
    { queryParams, options }: PaperLookupActionRequest,
) => apiGet<PaperBasicView[], "lookups/paperLookup">(
    "lookups/paperLookup",
    `lookups/paper`,
    queryParams,
    options,
), "lookups/paperLookup");

export interface PaperWeightsLookupActionRequest {
    options?: Partial<ApiRequestOptions<void, "lookups/paperWeightsLookup">>;
}

export const paperWeightsLookup = makeAction((
    { options }: PaperWeightsLookupActionRequest = {},
) => apiGet<void, "lookups/paperWeightsLookup">(
    "lookups/paperWeightsLookup",
    `lookups/paper-weights`,
    undefined,
    options,
), "lookups/paperWeightsLookup");

export interface PaymentMethodsLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<
            KeyValuePair<string, string>[],
            "lookups/paymentMethodsLookup"
        >
    >;
}

export const paymentMethodsLookup = makeAction((
    { options }: PaymentMethodsLookupActionRequest = {},
) => apiGet<KeyValuePair<string, string>[], "lookups/paymentMethodsLookup">(
    "lookups/paymentMethodsLookup",
    `lookups/payment-methods`,
    undefined,
    options,
), "lookups/paymentMethodsLookup");

export interface ApplicableItemLookupQueryParams {
    sheetId: string;
}

export interface ApplicableItemLookupActionRequest {
    queryParams: ApplicableItemLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            ApplicableItemLookupViewBase[],
            "lookups/applicableItemLookup"
        >
    >;
}

export const applicableItemLookup = makeAction((
    { queryParams, options }: ApplicableItemLookupActionRequest,
) => apiGet<ApplicableItemLookupViewBase[], "lookups/applicableItemLookup">(
    "lookups/applicableItemLookup",
    `lookups/product-schedule-item-unit`,
    queryParams,
    options,
), "lookups/applicableItemLookup");

export interface ProductScheduleStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<void, "lookups/productScheduleStatusLookup">
    >;
}

export const productScheduleStatusLookup = makeAction((
    { options }: ProductScheduleStatusLookupActionRequest = {},
) => apiGet<void, "lookups/productScheduleStatusLookup">(
    "lookups/productScheduleStatusLookup",
    `lookups/product-schedule-status`,
    undefined,
    options,
), "lookups/productScheduleStatusLookup");

export interface ProductScheduleTypeLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<void, "lookups/productScheduleTypeLookup">
    >;
}

export const productScheduleTypeLookup = makeAction((
    { options }: ProductScheduleTypeLookupActionRequest = {},
) => apiGet<void, "lookups/productScheduleTypeLookup">(
    "lookups/productScheduleTypeLookup",
    `lookups/product-schedule-type`,
    undefined,
    options,
), "lookups/productScheduleTypeLookup");

export interface RateGroupsDropdownActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/rateGroupsDropdown">
    >;
}

export const rateGroupsDropdown = makeAction((
    { options }: RateGroupsDropdownActionRequest = {},
) => apiGet<LookupView[], "lookups/rateGroupsDropdown">(
    "lookups/rateGroupsDropdown",
    `lookups/rate-groups`,
    undefined,
    options,
), "lookups/rateGroupsDropdown");

export interface RateTableTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<RateTypeLookupView[], "lookups/rateTableTypesLookup">
    >;
}

export const rateTableTypesLookup = makeAction((
    { options }: RateTableTypesLookupActionRequest = {},
) => apiGet<RateTypeLookupView[], "lookups/rateTableTypesLookup">(
    "lookups/rateTableTypesLookup",
    `lookups/rate-table-types`,
    undefined,
    options,
), "lookups/rateTableTypesLookup");

export interface RateTypeActionRequest {
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/rateType">>;
}

export const rateType = makeAction((
    { options }: RateTypeActionRequest = {},
) => apiGet<LookupView[], "lookups/rateType">(
    "lookups/rateType",
    `lookups/rate-types`,
    undefined,
    options,
), "lookups/rateType");

export interface ScheduleStatusLookupActionRequest {
    options?: Partial<ApiRequestOptions<void, "lookups/scheduleStatusLookup">>;
}

export const scheduleStatusLookup = makeAction((
    { options }: ScheduleStatusLookupActionRequest = {},
) => apiGet<void, "lookups/scheduleStatusLookup">(
    "lookups/scheduleStatusLookup",
    `lookups/schedule-status`,
    undefined,
    options,
), "lookups/scheduleStatusLookup");

export interface ServiceStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/serviceStatusLookup">
    >;
}

export const serviceStatusLookup = makeAction((
    { options }: ServiceStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/serviceStatusLookup">(
    "lookups/serviceStatusLookup",
    `lookups/service-statuses`,
    undefined,
    options,
), "lookups/serviceStatusLookup");

export interface ServicesLookupActionRequest {
    options?: Partial<ApiRequestOptions<void, "lookups/servicesLookup">>;
}

export const servicesLookup = makeAction((
    { options }: ServicesLookupActionRequest = {},
) => apiGet<void, "lookups/servicesLookup">(
    "lookups/servicesLookup",
    `lookups/services`,
    undefined,
    options,
), "lookups/servicesLookup");

export interface SettingsLookupQueryParams {
    search: string;
    dealerOfficeId: string;
}

export interface SettingsLookupActionRequest {
    queryParams: SettingsLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/settingsLookup">
    >;
}

export const settingsLookup = makeAction((
    { queryParams, options }: SettingsLookupActionRequest,
) => apiGet<LookupView[], "lookups/settingsLookup">(
    "lookups/settingsLookup",
    `lookups/settings`,
    queryParams,
    options,
), "lookups/settingsLookup");

export interface SettingsUploadEntityTypeLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<
            KeyValuePair<string, string>[],
            "lookups/settingsUploadEntityTypeLookup"
        >
    >;
}

export const settingsUploadEntityTypeLookup = makeAction((
    { options }: SettingsUploadEntityTypeLookupActionRequest = {},
) => apiGet<
    KeyValuePair<string, string>[],
    "lookups/settingsUploadEntityTypeLookup"
>(
    "lookups/settingsUploadEntityTypeLookup",
    `lookups/settings-upload-entity-type`,
    undefined,
    options,
), "lookups/settingsUploadEntityTypeLookup");

export interface SheetStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/sheetStatusLookup">
    >;
}

export const sheetStatusLookup = makeAction((
    { options }: SheetStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/sheetStatusLookup">(
    "lookups/sheetStatusLookup",
    `lookups/sheet-status`,
    undefined,
    options,
), "lookups/sheetStatusLookup");

export interface SheetTypeLookupActionRequest {
    options?: Partial<ApiRequestOptions<void, "lookups/sheetTypeLookup">>;
}

export const sheetTypeLookup = makeAction((
    { options }: SheetTypeLookupActionRequest = {},
) => apiGet<void, "lookups/sheetTypeLookup">(
    "lookups/sheetTypeLookup",
    `lookups/sheet-types`,
    undefined,
    options,
), "lookups/sheetTypeLookup");

export interface SpvStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<
            KeyValuePair<string, string>[],
            "lookups/spvStatusLookup"
        >
    >;
}

export const spvStatusLookup = makeAction((
    { options }: SpvStatusLookupActionRequest = {},
) => apiGet<KeyValuePair<string, string>[], "lookups/spvStatusLookup">(
    "lookups/spvStatusLookup",
    `lookups/spv-status`,
    undefined,
    options,
), "lookups/spvStatusLookup");

export interface SPVsLookupQueryParams {
    search: string;
}

export interface SPVsLookupActionRequest {
    queryParams: SPVsLookupQueryParams;
    options?: Partial<ApiRequestOptions<SPVBasicView[], "lookups/sPVsLookup">>;
}

export const sPVsLookup = makeAction((
    { queryParams, options }: SPVsLookupActionRequest,
) => apiGet<SPVBasicView[], "lookups/sPVsLookup">(
    "lookups/sPVsLookup",
    `lookups/spvs`,
    queryParams,
    options,
), "lookups/sPVsLookup");

export interface TargetMarketLookupQueryParams {
    dealerOfficeId: string;
}

export interface TargetMarketLookupActionRequest {
    queryParams: TargetMarketLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<
            TargetMarketLookupView[],
            "lookups/targetMarketLookup"
        >
    >;
}

export const targetMarketLookup = makeAction((
    { queryParams, options }: TargetMarketLookupActionRequest,
) => apiGet<TargetMarketLookupView[], "lookups/targetMarketLookup">(
    "lookups/targetMarketLookup",
    `lookups/target-market`,
    queryParams,
    options,
), "lookups/targetMarketLookup");

export interface UserAreaQueryParams {
    excludeNonUserAssignable: boolean;
    internalUserOnly: boolean;
    isSuperUser: boolean;
}

export interface UserAreaActionRequest {
    queryParams: UserAreaQueryParams;
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/userArea">>;
}

export const userArea = makeAction((
    { queryParams, options }: UserAreaActionRequest,
) => apiGet<LookupView[], "lookups/userArea">(
    "lookups/userArea",
    `lookups/user-areas`,
    queryParams,
    options,
), "lookups/userArea");

export interface UserTypeActionRequest {
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/userType">>;
}

export const userType = makeAction((
    { options }: UserTypeActionRequest = {},
) => apiGet<LookupView[], "lookups/userType">(
    "lookups/userType",
    `lookups/user-types`,
    undefined,
    options,
), "lookups/userType");

export interface UsersLookupQueryParams {
    dealerId: string;
    dealerOfficeId: string;
    search: string;
    userArea: UserArea;
    includeInternalUsers: boolean;
}

export interface UsersLookupActionRequest {
    queryParams: UsersLookupQueryParams;
    options?: Partial<
        ApiRequestOptions<UserBasicView[], "lookups/usersLookup">
    >;
}

export const usersLookup = makeAction((
    { queryParams, options }: UsersLookupActionRequest,
) => apiGet<UserBasicView[], "lookups/usersLookup">(
    "lookups/usersLookup",
    `lookups/users`,
    queryParams,
    options,
), "lookups/usersLookup");
