import FixedFieldType from "constants/forms/FixedFieldType";

export const determineFixType = (min, max, isFixed) => {
  if (!(min == null)) {
    if (!(max == null)) {
      return FixedFieldType.Range;
    }
    return FixedFieldType.Min;
  } else if (!(max == null)) {
    return FixedFieldType.Max;
  } else if (isFixed == true) {
    return FixedFieldType.Set;
  }
  return FixedFieldType.None;
};

export const newFixedInput = (value) => ({
  id: null,
  value: value,
  minimumValue: null,
  maximumValue: null,
  isFixed: null,
});

export const newFixedInputEmpty = () => ({
  id: null,
  value: "",
  minimumValue: null,
  maximumValue: null,
  isFixed: null,
});

export const newFixedBoolean = (value) => ({
  isFixed: false,
  value: value || false,
});

export const newFixedString = (value) => ({
  isFixed: false,
  value: value || "",
});

export const newFixedGuid = (value) => ({
  isFixed: false,
  value: value || "",
});

export const newFixedUnit = (value) => ({
  isFixed: false,
  value: value || "",
});

export const newFixedUnitFull = (
  value,
  code,
  name,
  overwritable,
  alwaysUnmanaged,
) => ({
  isFixed: false,
  value: value,
  unitCode: code,
  unitName: name,
  overwritable,
  alwaysUnmanaged,
});

export const stringifyNullableBool = (value) => {
  if (value === true || value === false) {
    return value.toString();
  }
  return null;
};

export const newFixedPaperWeight = () => ({
  paperWeightName: null,
  isFixed: false,
  value: null,
});
