import React from "react";
import { useTranslation } from "react-i18next";
import { crystalliseSheet } from "../actions";
import { ModalBuilder, Table } from "@redriver/cinnamon";
import { withRouter } from "react-router-dom";
import {
  withCurrentUser,
  PermissionCheck,
} from "features/../../../shared/components/auth";

import { Targets, Actions } from "constants/permissions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const CrystalliseSheet = ({ sheet, sheetActionRequired, onSubmitted }) => {
  const { t } = useTranslation("agreements");
  const { sheetId, sheetName, availableActions, isCrystallised } = sheet;

  if (!availableActions[sheetActionRequired] || isCrystallised) return null;

  return (
    <PermissionCheck
      target={Targets.Sheet}
      action={Actions.CrystalliseAndUncrystalliseSheets}
    >
      <ModalBuilder
        withForm
        submitAction={crystalliseSheet}
        submitParams={sheetId}
        onSubmitted={onSubmitted}
        renderTrigger={(trigger) => (
          <Table.MenuItem onClick={trigger}>
            {t("SheetActions.CrystalliseSheet.TriggerText")}
          </Table.MenuItem>
        )}
        renderModal={(modalProps) => (
          <TranslatedModal.Confirmation
            {...modalProps}
            header={t("SheetActions.CrystalliseSheet.HeaderText")}
            size="small"
          >
            {t("SheetActions.CrystalliseSheet.ConfirmMessage", { sheetName })}
          </TranslatedModal.Confirmation>
        )}
      />
    </PermissionCheck>
  );
};

export default withCurrentUser(withRouter(CrystalliseSheet));
